import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { useCard } from '../../../contexts/cardContext';

import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';


import { MdOutlineFilterList, MdGroups, MdOutlineSearch, MdOutlineUpdate } from "react-icons/md";
import { MdPerson, MdPhone, MdAttachMoney, MdDescription, MdLocalShipping, MdLocationCity, MdLabel, MdPublic } from 'react-icons/md';



import logoDefault from '../../../assets/logo-suite-flow.ico'

import logoPrefweb from '../../../assets/logo-prefweb.PNG'

import logoInstalacao from '../../../assets/logo-instalacao.PNG'
import logoMontagem from '../../../assets/logo-montagem.PNG'


import { useNavigate } from 'react-router-dom';

import './style.css';



function PortalPage() {
  const { user, empresa } = useUser(); // Reduzi para mostrar apenas variáveis usadas neste trecho


  const [conteudoSelecionado, setConteudoSelecionado] = useState(null);
  const [topicoSelecionado, setTopicoSelecionado] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState(null);



  const [conteudo, setConteudo] = useState([
    { id: 1, logo: logoPrefweb, description: 'PrefWeb' },
    { id: 2, logo: '', description: 'SyncsCRM' },
    { id: 3, logo: logoInstalacao, description: 'Instalação' },
    { id: 4, logo: logoMontagem, description: 'Produção' },
  ]);


  const [topicos, setTopicos] = useState([
    { id: 1, conteudo_id: 1, description: 'Diversos' },

    { id: 2, conteudo_id: 2, description: 'Diversos' },

    { id: 3, conteudo_id: 3, description: 'Diversos' },


    { id: 4, conteudo_id: 4, description: 'Diversos' },

  ]);


  const [midia, setMidia] = useState([
    { id: 1, topico_id: 1, description: 'Tutorial - Portas em cantos livre com n° de trilhos diferente | Orçamentos + PrefWeb #1', link: 'https://youtu.be/bD8FUwDT9-M?si=3XvWgVw9wcQDmIkm' },
    { id: 2, topico_id: 1, description: 'Tutorial - Edição múltipla do orçamento no Web | Orçamentos + PrefWeb #2', link: 'https://youtu.be/pozPmfYyDwk' },

    { id: 3, topico_id: 2, description: 'Tutorial - Implantação de pedidos | Orçamentos + SyncsCRM #1', link: 'https://youtu.be/Xiq5S8dn-4s?si=R1Sny4V-LLj8VHHJ' },
    { id: 4, topico_id: 2, description: 'Tutorial - Diferença entre colunas "Ajuste" e "Alteração" | Orçamentos + SyncsCRM #2', link: 'https://youtu.be/TIQwZWysJWE?si=RezSz7bb2fyLbfHy' },
    { id: 5, topico_id: 2, description: 'Tutorial - Como alterar um pedido no CRM | Orçamentos + SyncsCRM #3', link: 'https://youtu.be/6oBlAYYhVMs?si=66A9Eric0MM6Uhg4' },
  ]);



  const handleConteudoClick = (id) => {
    setConteudoSelecionado(id);
    setTopicoSelecionado(null); // Resetar seleção de tópicos
  };

  const handleTopicoClick = (id) => {
    setTopicoSelecionado(id);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };
  const closeModal = () => {
    setSelectedVideo(null);
  };


  const getYouTubeID = (url) => {
    const regExp = /^.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[1].length === 11 ? match[1] : null;
  };



  return (
    <div className='portal-page-container'>
      <Header />
      <div className='body-portal-container'>
        {/* Listar Conteúdos */}
        <div className='list-conteudo-principal'>
          {conteudo.map((item) => (
            <div
              key={item.id}
              className='item-list-conteudo-principal'
              onClick={() => handleConteudoClick(item.id)}
            >
              <div className='content-header'>
                <img
                  src={item.logo || logoDefault}
                  alt={`${item.description} logo`}
                  className='item-logo'
                />
              </div>
              <div className='content-description'>
                <h4>{item.description}</h4>
              </div>
            </div>
          ))}
        </div>

        {/* Listar Tópicos */}
        {conteudoSelecionado && (
          <div className='list-topicos'>
            <h3>Tópicos</h3>
            <div className='list-midia-container'>
              {topicos
                .filter((topico) => topico.conteudo_id === conteudoSelecionado)
                .map((topico) => (
                  <div
                    key={topico.id}
                    className='item-list-topico'
                    onClick={() => handleTopicoClick(topico.id)}
                  >
                    {topico.description}
                  </div>
                ))}
            </div>
          </div>
        )}
        {topicoSelecionado && (
          <div className='list-midia'>
            <h3>Mídias</h3>
            <div className='list-midia-container'>
              {midia
                .filter((m) => m.topico_id === topicoSelecionado)
                .map((m) => (

                  <div
                    key={m.id}
                    className='item-list-midia'
                    onClick={() => handleVideoClick(m)}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${getYouTubeID(m.link)}/hqdefault.jpg`}
                      alt={m.description}
                      className='video-thumbnail'
                    />
                    <p className='midia-description'>{m.description}</p>
                  </div>



                ))}
            </div>
          </div>
        )}

        {/* Modal de Vídeo */}
        {selectedVideo && (
          <div className='modal-portal'>
            <div className='modal-content-portal'>
              <button className='close-button-portal' onClick={closeModal}>
                Fechar
              </button>
              <iframe
                src={`https://www.youtube.com/embed/${getYouTubeID(selectedVideo.link)}`} // ✅ Sempre no formato correto
                title={selectedVideo.description}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              <p>{selectedVideo.description}</p>
            </div>
          </div>
        )}



      </div>
    </div>
  );
}

export default PortalPage;