import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { apiUrl } from '../../../config/apiConfig';

import Header from '../../Header';
import './style.css';
import ListColumns from '../../forms/ListColumns';
import CreateColumns from '../../forms/CreateColumns';

import ExcelJS from 'exceljs';
import api from '../../../services/api';










/**
 * Transforma o HTML fornecido para que todas as classes e ids sejam prefixadas,
 * e ajusta os seletores dentro de tags <style> para ficarem restritos ao container.
 *
 * @param {string} html - O HTML original digitado pelo usuário.
 * @param {string} prefix - O prefixo a ser adicionado (ex: 'bv-unique-').
 * @returns {string} - O HTML transformado.
 */
function transformHtmlToUnique(html, prefix = 'bv-unique-') {
  const container = document.createElement('div');
  container.innerHTML = html;

  const traverse = (node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      // Adiciona prefixo aos IDs
      if (node.id) {
        node.id = prefix + node.id;
      }

      // Adiciona prefixo às classes
      if (node.className) {
        const classes = node.className.split(' ').filter(c => c.trim() !== '');
        node.className = classes.map(cls => prefix + cls).join(' ');
      }

      // Ajuste nos estilos embutidos <style>
      if (node.tagName === 'STYLE') {
        node.textContent = node.textContent.split('}')
          .map(rule => {
            if (rule.trim() === '') return '';
            const parts = rule.split('{');
            if (parts.length < 2) return rule;
            
            // Aplica o prefixo no seletor
            const selectors = parts[0].split(',').map(sel => `#boas-vindas-container ${sel.trim()}`);
            return selectors.join(', ') + ' {' + parts[1];
          })
          .join('}');
      }

      // Processa os filhos recursivamente
      Array.from(node.children).forEach(child => traverse(child));
    }
  };

  traverse(container);
  return container.innerHTML;
}




function ColumnsPage() {
  const { user, empresa, setEmpresa } = useUser();
  const { openCloseCreateColumn, openModalCreateColumn } = useColumns();

  const [modalEtiquetas, setModalEtiquetas] = useState(false);
  const [modalOrigens, setModalOrigens] = useState(false);
  const [modalColunas, setModalColunas] = useState(false);
  const [modalProdutos, setModalProdutos] = useState(false);
  const [modalCores, setModalCores] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);

  const [modalEngajamentoUsers, setModalEngajamentoUsers] = useState(false);


  const [listaEtiquetas, setListaEtiquetas] = useState([]);
  const [listaOrigens, setListaOrigens] = useState([]);
  const [listaColunas, setListaColunas] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaCores, setListaCores] = useState([]);

  const [listaStatusDeColuna, setListaStatusDeColuna] = useState([]);

  const [colunaStatusVendido, setColunaStatusVendido] = useState('');
  const [colunaStatusPerdido, setColunaStatusPerdido] = useState('');
  const [colunaStatusArquivado, setColunaStatusArquivado] = useState('');
  const [colunaStatusPedido, setColunaStatusPedido] = useState('');

  const [modalBoasVindas, setModalBoasVindas] = useState(false);
  const [boasVindasHtml, setBoasVindasHtml] = useState('');

  // Estados para o HTML original e transformado
const [previewHtml, setPreviewHtml] = useState('');

  




  const [editingCor, setEditingCor] = useState(null);
  const [newColorName, setNewColorName] = useState('');
  const [selectedColorForEdit, setSelectedColorForEdit] = useState('');
  const [newColor, setNewColor] = useState(null);
  //const [newSelectedColor, setNewSelectedColor] = useState('');


  const [clientePodeVer, setClientePodeVer] = useState(false); // Novo estado para visibilidade do cliente








  // ----------- BTNS TOOLS --------------
  // const showModalStatus = () => {

  //   setColunaStatusVendido(empresa.coluna_vendido ? empresa.coluna_vendido : 'Não definido!')
  //   setColunaStatusPerdido(empresa.coluna_perdido ? empresa.coluna_perdido : 'Não definido!')
  //   setColunaStatusArquivado(empresa.coluna_arquivado ? empresa.coluna_arquivado : 'Não definido!')

  //   setModalStatus(!modalStatus);

  // };

  const showModalStatus = () => {
    setColunaStatusVendido(empresa.coluna_vendido ? empresa.coluna_vendido : 'Não definido!');
    setColunaStatusPerdido(empresa.coluna_perdido ? empresa.coluna_perdido : 'Não definido!');
    setColunaStatusArquivado(empresa.coluna_arquivado ? empresa.coluna_arquivado : 'Não definido!');
    setColunaStatusPedido(empresa.pedido_coluna ? empresa.pedido_coluna : 'Não definido!'); // Novo estado
    setModalStatus(!modalStatus);
  };

  const showModalCores = () => {
    getCores();
    setModalCores(!modalCores);
  };


  const showModalEtiquetas = () => {
    getEtiquetas();
    setModalEtiquetas(!modalEtiquetas);
  };


  const engajamentoUsers = () => {
    setModalEngajamentoUsers(!modalEngajamentoUsers);
  };




  const showModalOrigens = () => {
    getOrigens();
    setModalOrigens(!modalOrigens);
  };

  // const showModalColunas = () => {
  //   getColumns();
  //   setModalColunas(!modalColunas);
  // };

  const showModalProdutos = () => {
    getProdutos();
    setModalProdutos(!modalProdutos);
  };




  // ----------- Get --------------




  // const getCores = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getCores/${user.empresa_id}`);
  //     setListaCores(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Cores:', error);
  //   }
  // };

  const getCores = async () => {
    try {
      const response = await api.get(`/users/getCores/${user.empresa_id}`);
      setListaCores(response.data);
    } catch (error) {
      console.error('Erro ao buscar Cores:', error);
    }
  };
  


  // const getEtiquetas = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getEtiquetas/${user.empresa_id}`);
  //     setListaEtiquetas(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Etiquetas:', error);
  //   }
  // };

  // const getColumns = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getColumns/${user.empresa_id}`);
  //     const orderedColumns = response.data.sort((a, b) => a.display_order - b.display_order);
  //     setListaColunas(orderedColumns);
  //   } catch (error) {
  //     console.error('Erro ao buscar Colunas:', error);
  //   }
  // };


  const getColumns = async () => {
    try {
      const response = await api.get(`/users/getColumns/${user.empresa_id}`);
      const orderedColumns = response.data.sort((a, b) => a.display_order - b.display_order);
      setListaColunas(orderedColumns);
    } catch (error) {
      console.error('Erro ao buscar Colunas:', error);
    }
  };
  

  // const getEtiquetas = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getEtiquetas/${user.empresa_id}`);
  //     const orderedEtiquetas = response.data.sort((a, b) => a.order - b.order);
  //     setListaEtiquetas(orderedEtiquetas);
  //   } catch (error) {
  //     console.error('Erro ao buscar Etiquetas:', error);
  //   }
  // };

  const getEtiquetas = async () => {
    try {
      const response = await api.get(`/users/getEtiquetas/${user.empresa_id}`);
      const orderedEtiquetas = response.data.sort((a, b) => a.order - b.order);
      setListaEtiquetas(orderedEtiquetas);
    } catch (error) {
      console.error('Erro ao buscar Etiquetas:', error);
    }
  };
  


  // const getOrigens = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getOrigens/${user.empresa_id}`);
  //     setListaOrigens(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Origens:', error);
  //   }
  // };

  const getOrigens = async () => {
    try {
      const response = await api.get(`/users/getOrigens/${user.empresa_id}`);
      setListaOrigens(response.data);
    } catch (error) {
      console.error('Erro ao buscar Origens:', error);
    }
  };
  

  // const getColumns = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getColumns/${user.empresa_id}`);
  //     setListaColunas(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Colunas:', error);
  //   }
  // };

  // const getProdutos = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getProdutos/${user.empresa_id}`);
  //     setListaProdutos(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Produtos:', error);
  //   }
  // };
  const getProdutos = async () => {
    try {
      const response = await api.get(`/users/getProdutos/${user.empresa_id}`);
      setListaProdutos(response.data);
    } catch (error) {
      console.error('Erro ao buscar Produtos:', error);
    }
  };
  




  // const createCor = async () => {
  //   const newName = prompt('Novo nome da cor:', '');
  //   const descricao = prompt('Descrição da cor:', '');

  //   if (newName && descricao) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         descricao: descricao,
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createCor`, itemData);
  //       showModalCores();
  //     } catch (error) {
  //       console.error('Erro ao criar Cor:', error);
  //     }
  //   }
  // };


  // const createEtiqueta = async () => {
  //   const newName = prompt('Novo nome:', '');
  //   const newOrder = prompt('Ordem:', '');
  //   const newColor = prompt('Cor:', '');


  //   if (newName && newOrder, newColor) {

  //     try {
  //       const itemData = {
  //         description: newName,
  //         color: newColor,
  //         order: parseInt(newOrder, 10),
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createEtiqueta`, itemData);
  //       showModalEtiquetas();
  //     } catch (error) {
  //       console.error('Erro ao criar Etiqueta:', error);
  //     }

  //   };
  // }



  // const createOrigem = async () => {
  //   const newName = prompt('Novo nome da origem:', '');
  //   const descricao = prompt('Descrição da origem:', '');

  //   if (newName && descricao) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         descricao: descricao,
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createOrigem`, itemData);
  //       showModalOrigens();
  //     } catch (error) {
  //       console.error('Erro ao criar Origem:', error);
  //     }
  //   }
  // };

  const createOrigem = async () => {
    const newName = prompt('Novo nome da origem:', '');
    const descricao = prompt('Descrição da origem:', '');
  
    if (newName && descricao) {
      try {
        const itemData = {
          name: newName,
          descricao: descricao,
          empresa_id: user.empresa_id,
        };
  
        await api.post(`/users/createOrigem`, itemData);
        showModalOrigens();
      } catch (error) {
        console.error('Erro ao criar Origem:', error);
      }
    }
  };
  

  // const createColuna = async () => {
  //   const newName = prompt('Novo nome da coluna:', '');
  //   const displayOrder = prompt('Ordem de exibição:', '');
  //   const description = prompt('Descrição da coluna:', '');

  //   if (newName && displayOrder && description) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         display_order: parseInt(displayOrder, 10),
  //         description: description,
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createColuna`, itemData);
  //       showModalColunas();
  //     } catch (error) {
  //       console.error('Erro ao criar Coluna:', error);
  //     }
  //   }
  // };


  // const createColuna = async () => {
  //   const newName = prompt('Novo nome da coluna:', '');
  //   const displayOrder = prompt('Ordem de exibição:', '');
  //   const description = prompt('Descrição da coluna:', '');

  //   if (newName && displayOrder && description) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         display_order: parseInt(displayOrder, 10),
  //         description: description,
  //         empresa_id: user.empresa_id,
  //         cliente_pode_ver: clientePodeVer, // Adicionar visibilidade do cliente
  //       };

  //       await axios.post(`${apiUrl}/users/createColuna`, itemData);
  //       showModalColunas();
  //     } catch (error) {
  //       console.error('Erro ao criar Coluna:', error);
  //     }
  //   }
  // };

  // const createColuna = async () => {
  //   if (newName && newDisplayOrder && newDescription) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         display_order: parseInt(newDisplayOrder, 10),
  //         description: newDescription,
  //         empresa_id: user.empresa_id,
  //         cliente_pode_ver: clientePodeVer, // Adicionar visibilidade do cliente
  //       };

  //       await axios.post(`${apiUrl}/users/createColuna`, itemData);
  //       getColumns(); // Atualiza a lista de colunas após a criação
  //       closeCreateColumnModal(); // Fecha o modal de criação após salvar
  //     } catch (error) {
  //       console.error('Erro ao criar Coluna:', error);
  //     }
  //   } else {
  //     alert('Por favor, preencha todos os campos antes de salvar.');
  //   }
  // };

  const createColuna = async () => {
    if (newName && newDisplayOrder && newDescription) {
      try {
        const itemData = {
          name: newName,
          display_order: parseInt(newDisplayOrder, 10),
          description: newDescription,
          empresa_id: user.empresa_id,
          cliente_pode_ver: clientePodeVer, // Adicionar visibilidade do cliente
        };
  
        await api.post(`/users/createColuna`, itemData);
        getColumns(); // Atualiza a lista de colunas após a criação
        closeCreateColumnModal(); // Fecha o modal de criação após salvar
      } catch (error) {
        console.error('Erro ao criar Coluna:', error);
      }
    } else {
      alert('Por favor, preencha todos os campos antes de salvar.');
    }
  };
  


  // const createProduto = async () => {
  //   const newName = prompt('Novo nome do produto:', '');
  //   const descricao = prompt('Descrição do produto:', '');

  //   if (newName && descricao) {
  //     try {
  //       const itemData = {
  //         name: newName,
  //         descricao: descricao,
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createProduto`, itemData);
  //       showModalProdutos();
  //     } catch (error) {
  //       console.error('Erro ao criar Produto:', error);
  //     }
  //   }
  // };

  const createProduto = async () => {
    const newName = prompt('Novo nome do produto:', '');
    const descricao = prompt('Descrição do produto:', '');
  
    if (newName && descricao) {
      try {
        const itemData = {
          name: newName,
          descricao: descricao,
          empresa_id: user.empresa_id,
        };
  
        await api.post(`/users/createProduto`, itemData);
        showModalProdutos(); // Atualiza a lista de produtos ou exibe o modal atualizado
      } catch (error) {
        console.error('Erro ao criar Produto:', error);
      }
    }
  };
  

  // ----------- Edite --------------


  // const editeColunaStatusVendido = async () => {
  //   const newName = prompt('Novo nome:', colunaStatusVendido);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateColunaVendido/${user.empresa_id}`, { coluna_vendido: newName });
  //       setColunaStatusVendido(newName);
  //     } catch (error) {
  //       console.error('Erro ao editar coluna vendido:', error);
  //     }
  //   }
  // };

  const editeColunaStatusVendido = async () => {
    const newName = prompt('Novo nome:', colunaStatusVendido);
    if (newName) {
      try {
        await api.put(`/users/updateColunaVendido/${user.empresa_id}`, { coluna_vendido: newName });
        setColunaStatusVendido(newName);
      } catch (error) {
        console.error('Erro ao editar coluna vendido:', error);
      }
    }
  };
  


  // const editeColunaStatusPedido = async () => {
  //   const newName = prompt('Novo nome:', colunaStatusPedido);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateColunaPedido/${user.empresa_id}`, { pedido_coluna: newName });
  //       setColunaStatusPedido(newName);

  //       // Atualizar o contexto da empresa
  //       setEmpresa((prevEmpresa) => ({
  //         ...prevEmpresa,
  //         pedido_coluna: newName, // Atualiza o pedido_coluna no estado da empresa
  //       }));


  //     } catch (error) {
  //       console.error('Erro ao editar coluna pedido:', error);
  //     }
  //   }
  // };

  const editeColunaStatusPedido = async () => {
    const newName = prompt('Novo nome:', colunaStatusPedido);
    if (newName) {
      try {
        await api.put(`/users/updateColunaPedido/${user.empresa_id}`, { pedido_coluna: newName });
        setColunaStatusPedido(newName);
  
        // Atualizar o contexto da empresa
        setEmpresa((prevEmpresa) => ({
          ...prevEmpresa,
          pedido_coluna: newName, // Atualiza o pedido_coluna no estado da empresa
        }));
        
      } catch (error) {
        console.error('Erro ao editar coluna pedido:', error);
      }
    }
  };
  


  // const editeColunaStatusPerdido = async () => {
  //   const newName = prompt('Novo nome:', colunaStatusPerdido);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateColunaPerdido/${user.empresa_id}`, { coluna_perdido: newName });
  //       setColunaStatusPerdido(newName);
  //     } catch (error) {
  //       console.error('Erro ao editar coluna perdido:', error);
  //     }
  //   }
  // };

  const editeColunaStatusPerdido = async () => {
    const newName = prompt('Novo nome:', colunaStatusPerdido);
    if (newName) {
      try {
        await api.put(`/users/updateColunaPerdido/${user.empresa_id}`, { coluna_perdido: newName });
        setColunaStatusPerdido(newName);
      } catch (error) {
        console.error('Erro ao editar coluna perdido:', error);
      }
    }
  };
  

  // const editeColunaStatusArquivado = async () => {
  //   const newName = prompt('Novo nome:', colunaStatusArquivado);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateColunaArquivado/${user.empresa_id}`, { coluna_arquivado: newName });
  //       setColunaStatusArquivado(newName);
  //     } catch (error) {
  //       console.error('Erro ao editar coluna arquivado:', error);
  //     }
  //   }
  // };

  const editeColunaStatusArquivado = async () => {
    const newName = prompt('Novo nome:', colunaStatusArquivado);
    if (newName) {
      try {
        await api.put(`/users/updateColunaArquivado/${user.empresa_id}`, { coluna_arquivado: newName });
        setColunaStatusArquivado(newName);
      } catch (error) {
        console.error('Erro ao editar coluna arquivado:', error);
      }
    }
  };
  



  const editeCor = (item) => {
    setEditingCor(item);
    setNewColorName(item.name);
    setSelectedColorForEdit(item.color_code); // Mudança aqui para color_code
  };

  // const saveCorEdits = async () => {
  //   if (editingCor) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateCor/${editingCor.id}`, {
  //         name: newColorName,
  //         color_code: selectedColorForEdit, // Mudança aqui para color_code
  //       });

  //       setListaCores(listaCores.map(itemList =>
  //         itemList.id === editingCor.id ? { ...itemList, name: newColorName, color_code: selectedColorForEdit } : itemList
  //       ));
  //       setEditingCor(null); // Fechar o modal de edição
  //     } catch (error) {
  //       console.error('Erro ao editar Cor:', error);
  //     }
  //   }
  // };

  const saveCorEdits = async () => {
    if (editingCor) {
      try {
        await api.put(`/users/updateCor/${editingCor.id}`, {
          name: newColorName,
          color_code: selectedColorForEdit, // Mudança aqui para color_code
        });
  
        setListaCores(listaCores.map(itemList =>
          itemList.id === editingCor.id ? { ...itemList, name: newColorName, color_code: selectedColorForEdit } : itemList
        ));
        setEditingCor(null); // Fechar o modal de edição
      } catch (error) {
        console.error('Erro ao editar Cor:', error);
      }
    }
  };
  


  const createCor = () => {
    setNewColor(true);
    setNewColorName('');
    setNewSelectedColor('');
  };

  // const saveNewCor = async () => {
  //   if (newColorName && newSelectedColor) {
  //     try {
  //       const itemData = {
  //         name: newColorName,
  //         color_code: newSelectedColor, // Mudança aqui para color_code
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createCor`, itemData);

  //       const response = await axios.get(`${apiUrl}/users/getCores/${user.empresa_id}`);
  //       setListaCores(response.data);

  //       setNewColor(null); // Fechar o modal de criação
  //     } catch (error) {
  //       console.error('Erro ao criar Cor:', error);
  //     }
  //   }
  // };


  const saveNewCor = async () => {
    if (newColorName && newSelectedColor) {
      try {
        const itemData = {
          name: newColorName,
          color_code: newSelectedColor, // Mudança aqui para color_code
          empresa_id: user.empresa_id,
        };
  
        await api.post(`/users/createCor`, itemData);
  
        const response = await api.get(`/users/getCores/${user.empresa_id}`);
        setListaCores(response.data);
  
        setNewColor(null); // Fechar o modal de criação
      } catch (error) {
        console.error('Erro ao criar Cor:', error);
      }
    }
  };
  


  // const editeOrigem = async (item) => {
  //   const newName = prompt('Novo nome:', item.name);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateOrigem/${item.id}`, { name: newName });

  //       setListaOrigens(listaOrigens.map(itemList => itemList.id === item.id ? { ...itemList, name: newName } : itemList));
  //     } catch (error) {
  //       console.error('Erro ao editar Origens:', error);
  //     }
  //   }
  // };

  const editeOrigem = async (item) => {
    const newName = prompt('Novo nome:', item.name);
    if (newName) {
      try {
        await api.put(`/users/updateOrigem/${item.id}`, { name: newName });
  
        setListaOrigens(listaOrigens.map(itemList => 
          itemList.id === item.id ? { ...itemList, name: newName } : itemList
        ));
      } catch (error) {
        console.error('Erro ao editar Origens:', error);
      }
    }
  };
  



  // const editeProduto = async (item) => {
  //   const newName = prompt('Novo nome:', item.name);
  //   if (newName) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateProduto/${item.id}`, { name: newName });

  //       setListaProdutos(listaProdutos.map(itemList => itemList.id === item.id ? { ...itemList, name: newName } : itemList));
  //     } catch (error) {
  //       console.error('Erro ao editar Produto:', error);
  //     }
  //   }
  // };


  const editeProduto = async (item) => {
    const newName = prompt('Novo nome:', item.name);
    if (newName) {
      try {
        await api.put(`/users/updateProduto/${item.id}`, { name: newName });
  
        setListaProdutos(listaProdutos.map(itemList => 
          itemList.id === item.id ? { ...itemList, name: newName } : itemList
        ));
      } catch (error) {
        console.error('Erro ao editar Produto:', error);
      }
    }
  };
  

  // ----------- Remove --------------

  // const removeCor = async (id) => {
  //   const isBlocked = true;

  //   // if (isBlocked) {
  //   //   alert('Bloqueado pelo ADM');
  //   //   return;
  //   // }

  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteCor/${id}`);
  //     setListaCores(listaCores.filter(itemList => itemList.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Cor:', error);
  //   }
  // };

  const removeCor = async (id) => {
    const isBlocked = true;
  
    // if (isBlocked) {
    //   alert('Bloqueado pelo ADM');
    //   return;
    // }
  
    try {
      await api.delete(`/users/deleteCor/${id}`);
      setListaCores(listaCores.filter(itemList => itemList.id !== id));
    } catch (error) {
      console.error('Erro ao excluir Cor:', error);
    }
  };
  

  // const removeEtiqueta = async (id) => {
  //   const isBlocked = true;

  //   if (isBlocked) {
  //     alert('Bloqueado pelo ADM');
  //     return;
  //   }

  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteEtiqueta/${id}`);
  //     setListaEtiquetas(listaEtiquetas.filter(itemList => itemList.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Etiqueta:', error);
  //   }
  // };


  const removeEtiqueta = async (id) => {
    const isBlocked = true;
  
    if (isBlocked) {
      alert('Bloqueado pelo ADM');
      return;
    }
  
    try {
      await api.delete(`/users/deleteEtiqueta/${id}`);
      setListaEtiquetas(listaEtiquetas.filter(itemList => itemList.id !== id));
    } catch (error) {
      console.error('Erro ao excluir Etiqueta:', error);
    }
  };
  

  // const removeOrigem = async (id) => {
  //   const isBlocked = true;

  //   if (isBlocked) {
  //     alert('Bloqueado pelo ADM');
  //     return;
  //   }

  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteOrigem/${id}`);
  //     setListaOrigens(listaOrigens.filter(itemList => itemList.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Origem:', error);
  //   }
  // };

  const removeOrigem = async (id) => {
    const isBlocked = true;
  
    if (isBlocked) {
      alert('Bloqueado pelo ADM');
      return;
    }
  
    try {
      await api.delete(`/users/deleteOrigem/${id}`);
      setListaOrigens(listaOrigens.filter(itemList => itemList.id !== id));
    } catch (error) {
      console.error('Erro ao excluir Origem:', error);
    }
  };
  

  // const removeColuna = async (id) => {
  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteColuna/${id}`);
  //     setListaColunas(listaColunas.filter(itemList => itemList.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Coluna:', error);
  //   }
  // };

  // const removeProduto = async (id) => {
  //   const isBlocked = true;

  //   if (isBlocked) {
  //     alert('Bloqueado pelo ADM');
  //     return;
  //   }

  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteProduto/${id}`);
  //     setListaProdutos(listaProdutos.filter(itemList => itemList.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Produto:', error);
  //   }
  // };

  const removeProduto = async (id) => {
    const isBlocked = true;
  
    if (isBlocked) {
      alert('Bloqueado pelo ADM');
      return;
    }
  
    try {
      await api.delete(`/users/deleteProduto/${id}`);
      setListaProdutos(listaProdutos.filter(itemList => itemList.id !== id));
    } catch (error) {
      console.error('Erro ao excluir Produto:', error);
    }
  };
  




  const [editingEtiqueta, setEditingEtiqueta] = useState(null);
  const [newDescription, setNewDescription] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [newOrder, setNewOrder] = useState('');


  const [availableColors, setAvailableColors] = useState([
    // Tons em vermelho (pastéis)
    "#FFB3B3", "#FFCCCC", "#FFE6E6", "#FF9999", "#FF8080", "#FF6666", "#FF4D4D", "#FF3333", "#FF1A1A", "#FF9999", "#FFB3B3",
    // Tons em azul (pastéis)
    "#B3D9FF", "#CCE6FF", "#E6F2FF", "#99CCFF", "#80BFFF", "#66B3FF", "#4DA6FF", "#3399FF", "#1A8CFF", "#99CCFF", "#B3D9FF",
    // Tons em verde (pastéis)
    "#B3FFB3", "#CCFFCC", "#E6FFE6", "#99FF99", "#80FF80", "#66FF66", "#4DFF4D", "#33FF33", "#1AFF1A", "#99FF99", "#B3FFB3",
    // Tons em laranja (pastéis)
    "#FFD9B3", "#FFE6CC", "#FFF2E6", "#FFCC99", "#FFB380", "#FF9966", "#FF804D", "#FF6633", "#FF4D1A", "#FFCC99", "#FFD9B3",
    // Tons em rosa (pastéis)
    "#FFCCE6", "#FFE6F2", "#FFF2F8", "#FF99CC", "#FF80B2", "#FF6699", "#FF4D80", "#FF3366", "#FF1A4D", "#FF99CC", "#FFCCE6",
    // Tons em roxo (pastéis)
    "#D9B3FF", "#E6CCFF", "#F2E6FF", "#CC99FF", "#B380FF", "#9966FF", "#804DFF", "#6633FF", "#4D1AFF", "#CC99FF", "#D9B3FF",
    // Tons em amarelo (pastéis)
    "#FFFFB3", "#FFFFCC", "#FFFFE6", "#FFFF99", "#FFFF80", "#FFFF66", "#FFFF4D", "#FFFF33", "#FFFF1A", "#FFFF99", "#FFFFB3",
    // Tons variados (pastéis)
    "#FFCEB3", "#FFD1B2", "#FFDB99", "#DBFFB3", "#B3FF99", "#99FFD1", "#B2E6FF", "#DBB3FF", "#FFB3D9", "#FFB2CE", "#FFDAB9",
    // Preto, Bronze, Amadeirado, Pirita, Cinza
    "#000000", "#CD7F32", "#8B4513", "#B8860B", "#808080"
  ]);









  const editeEtiqueta = (item) => {
    setEditingEtiqueta(item);
    setNewDescription(item.description);
    setSelectedColor(item.color);
    setNewOrder(item.order);
  };



  // const saveEtiquetaEdits = async () => {
  //   if (editingEtiqueta) {
  //     try {
  //       await axios.put(`${apiUrl}/users/updateEtiqueta/${editingEtiqueta.id}`, {
  //         description: newDescription,
  //         color: selectedColor,
  //         order: newOrder
  //       });

  //       setListaEtiquetas(listaEtiquetas.map(itemList =>
  //         itemList.id === editingEtiqueta.id ? { ...itemList, description: newDescription, color: selectedColor, order: newOrder } : itemList
  //       ));
  //       setEditingEtiqueta(null); // Fechar o modal de edição
  //     } catch (error) {
  //       console.error('Erro ao editar Etiqueta:', error);
  //     }
  //   }
  // };

  const saveEtiquetaEdits = async () => {
    if (editingEtiqueta) {
      try {
        await api.put(`/users/updateEtiqueta/${editingEtiqueta.id}`, {
          description: newDescription,
          color: selectedColor,
          order: newOrder
        });
  
        setListaEtiquetas(listaEtiquetas.map(itemList =>
          itemList.id === editingEtiqueta.id 
            ? { ...itemList, description: newDescription, color: selectedColor, order: newOrder } 
            : itemList
        ));
        setEditingEtiqueta(null); // Fechar o modal de edição
      } catch (error) {
        console.error('Erro ao editar Etiqueta:', error);
      }
    }
  };
  






  const [newEtiqueta, setNewEtiqueta] = useState(null);
  const [newSelectedColor, setNewSelectedColor] = useState('');

  const showCreateEtiquetaModal = () => {
    setNewEtiqueta(true);
    setNewDescription('');
    setNewSelectedColor('');
    setNewOrder('');
  };

  const hideCreateEtiquetaModal = () => {
    setNewEtiqueta(null);
  };


  // const saveNewEtiqueta = async () => {
  //   if (newDescription && newSelectedColor && newOrder) {
  //     try {
  //       const itemData = {
  //         description: newDescription,
  //         color: newSelectedColor,
  //         order: parseInt(newOrder, 10),
  //         empresa_id: user.empresa_id,
  //       };

  //       await axios.post(`${apiUrl}/users/createEtiqueta`, itemData);

  //       const response = await axios.get(`${apiUrl}/users/getEtiquetas/${user.empresa_id}`);
  //       const orderedEtiquetas = response.data.sort((a, b) => a.order - b.order);
  //       setListaEtiquetas(orderedEtiquetas);

  //       hideCreateEtiquetaModal(); // Fechar o modal de criação
  //     } catch (error) {
  //       console.error('Erro ao criar Etiqueta:', error);
  //     }
  //   }
  // };

  const saveNewEtiqueta = async () => {
    if (newDescription && newSelectedColor && newOrder) {
      try {
        const itemData = {
          description: newDescription,
          color: newSelectedColor,
          order: parseInt(newOrder, 10),
          empresa_id: user.empresa_id,
        };
  
        await api.post(`/users/createEtiqueta`, itemData);
  
        const response = await api.get(`/users/getEtiquetas/${user.empresa_id}`);
        const orderedEtiquetas = response.data.sort((a, b) => a.order - b.order);
        setListaEtiquetas(orderedEtiquetas);
  
        hideCreateEtiquetaModal(); // Fechar o modal de criação
      } catch (error) {
        console.error('Erro ao criar Etiqueta:', error);
      }
    }
  };
  




  const [editingColumn, setEditingColumn] = useState(null);
  const [newName, setNewName] = useState('');
  const [newDisplayOrder, setNewDisplayOrder] = useState('');
  const [newSetor, setNewSetor] = useState('');

  const [columnID, setCcolumnID] = useState(null);

  const showModalColunas = () => {
    getColumns();
    setModalColunas(!modalColunas);
  };

  // const getColumns = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/getColumns/${user.empresa_id}`);
  //     setListaColunas(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar Colunas:', error);
  //   }
  // };


  const editColumn = (column) => {
    setEditingColumn(column);
    setNewName(column.name);
    setNewDisplayOrder(column.display_order);
    setNewDescription(column.description);
    setNewSetor(column.setor);
    setClientePodeVer(column.cliente_pode_ver); // Setar valor inicial do checkbox
    setCcolumnID(column.id)
  };




  // const saveColumnEdits = async () => {
  //   if (editingColumn) {
  //     try {
  //       const updatedColumn = {
  //         name: newName,
  //         display_order: newDisplayOrder,
  //         description: newDescription,
  //         setor: newSetor
  //       };
  //       await axios.put(`${apiUrl}/users/updateColuna/${editingColumn.id}`, updatedColumn);
  //       setListaColunas(listaColunas.map(col => col.id === editingColumn.id ? { ...col, ...updatedColumn } : col));
  //       setEditingColumn(null); // Fechar o modal de edição
  //     } catch (error) {
  //       console.error('Erro ao editar Coluna:', error);
  //     }
  //   }
  // };







  //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
    //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
      //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
        //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
          //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
            //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA

              //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
                  //// ------------------------------------- AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA

  // const saveColumnEdits = async () => {
  //   if (editingColumn) {
  //     try {
  //       const updatedColumn = {
  //         name: newName,
  //         display_order: newDisplayOrder,
  //         description: newDescription,
  //         setor: newSetor,
  //         cliente_pode_ver: clientePodeVer, // Adicionar visibilidade do cliente
  //       };
  //       await axios.put(`${apiUrl}/users/updateColuna/${editingColumn.id}`, updatedColumn);
  //       setListaColunas(listaColunas.map(col => col.id === editingColumn.id ? { ...col, ...updatedColumn } : col));
  //       setEditingColumn(null); // Fechar o modal de edição
  //     } catch (error) {
  //       console.error('Erro ao editar Coluna:', error);
  //     }
  //   }
  // };

  const saveColumnEdits = async () => {
    if (editingColumn) {
      try {
        const updatedColumn = {
          name: newName,
          display_order: newDisplayOrder,
          description: newDescription,
          setor: newSetor,
          cliente_pode_ver: clientePodeVer, // Adicionar visibilidade do cliente
        };
        
        await api.put(`/users/updateColuna/${editingColumn.id}`, updatedColumn);
        
        setListaColunas(listaColunas.map(col => 
          col.id === editingColumn.id ? { ...col, ...updatedColumn } : col
        ));
  
        setEditingColumn(null); // Fechar o modal de edição
      } catch (error) {
        console.error('Erro ao editar Coluna:', error);
      }
    }
  };
  


  // const removeColuna = async (id) => {
  //   try {
  //     await axios.delete(`${apiUrl}/users/deleteColuna/${id}`);
  //     setListaColunas(listaColunas.filter(item => item.id !== id));
  //   } catch (error) {
  //     console.error('Erro ao excluir Coluna:', error);
  //   }
  // };

  const removeColuna = async (id) => {
    try {
      await api.delete(`/users/deleteColuna/${id}`);
      setListaColunas(listaColunas.filter(item => item.id !== id));
    } catch (error) {
      console.error('Erro ao excluir Coluna:', error);
    }
  };
  

  const [creatingColumn, setCreatingColumn] = useState(false);

  const openCreateColumnModal = () => {
    setCreatingColumn(true);
  };

  const closeCreateColumnModal = () => {
    setCreatingColumn(false);
  };





  // const exportarTabelasExcel = async () => {

  //   const userConfirmed = window.confirm(`Exportar Planilhas?`);
  //   if (!userConfirmed) {
  //     return;
  //   }

  //   const tables = ['cards', 'users', 'modulo_esquadrias', 'history', 'tasks'];

  //   for (const table of tables) {
  //     try {
  //       const response = await axios.post(`${apiUrl}/users/${table}`, {
  //         empresa_id: user.empresa_id,
  //       });
  //       const data = response.data;

  //       if (data.length > 0) {
  //         const workbook = new ExcelJS.Workbook();
  //         const worksheet = workbook.addWorksheet(table);

  //         worksheet.columns = Object.keys(data[0]).map((key) => ({
  //           header: key,
  //           key: key,
  //           width: 20,
  //         }));

  //         data.forEach((row) => {
  //           worksheet.addRow(row);
  //         });

  //         const buffer = await workbook.xlsx.writeBuffer();
  //         const blob = new Blob([buffer], {
  //           type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //         });

  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', `${table}.xlsx`);
  //         document.body.appendChild(link);
  //         link.click();
  //       }
  //     } catch (error) {
  //       console.error(`Erro ao exportar tabela ${table}:`, error);
  //     }
  //   }
  // };

  const exportarTabelasExcel = async () => {
    const userConfirmed = window.confirm(`Exportar Planilhas?`);
    if (!userConfirmed) {
      return;
    }
  
    const tables = ['cards', 'users', 'modulo_esquadrias', 'history', 'tasks'];
  
    for (const table of tables) {
      try {
        const response = await api.post(`/users/${table}`, {
          empresa_id: user.empresa_id,
        });
        const data = response.data;
  
        if (data.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(table);
  
          worksheet.columns = Object.keys(data[0]).map((key) => ({
            header: key,
            key: key,
            width: 20,
          }));
  
          data.forEach((row) => {
            worksheet.addRow(row);
          });
  
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
  
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${table}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error(`Erro ao exportar tabela ${table}:`, error);
      }
    }
  };
  




  // const associarColunaParaTodosOsUsuarios = async (id) => {
  //   const confirmar = window.confirm('Você deseja associar a coluna a todos os usuários da empresa?');
  //   if (!confirmar) return;

  //   try {
  //     await axios.post(
  //       `${apiUrl}/users/column-all-users/${id}/view`,
  //       { empresaId: Number(user.empresa_id) }, // Certifique-se de que está enviando um número
  //       { headers: { Authorization: `Bearer ${user.token}` } }
  //     );
  //     console.log('Coluna associada a todos os usuários com sucesso.');
  //   } catch (error) {
  //     console.error('Erro ao associar coluna:', error);
  //   }
  // };



  const associarColunaParaTodosOsUsuarios = async (id) => {
    const confirmar = window.confirm('Você deseja associar a coluna a todos os usuários da empresa?');
    if (!confirmar) return;
  
    try {
      await api.post(`/users/column-all-users/${id}/view`, {
        empresaId: Number(user.empresa_id) // Certifique-se de que está enviando um número
      });
  
      console.log('Coluna associada a todos os usuários com sucesso.');
    } catch (error) {
      console.error('Erro ao associar coluna:', error);
    }
  };
  

  // const associarColunaParaTodosOsUsuariosEditar = async (id) => {
  //   const confirmar = window.confirm('Você deseja associar a coluna para edição a todos os usuários da empresa?');
  //   if (!confirmar) return;

  //   try {
  //     await axios.post(
  //       `${apiUrl}/users/column-all-users/${id}/edit`,
  //       { empresaId: Number(user.empresa_id) }, // Certifique-se de que está enviando um número
  //       { headers: { Authorization: `Bearer ${user.token}` } }
  //     );
  //     console.log('Coluna associada para edição a todos os usuários com sucesso.');
  //   } catch (error) {
  //     console.error('Erro ao associar coluna para edição:', error);
  //   }
  // };


  const associarColunaParaTodosOsUsuariosEditar = async (id) => {
    const confirmar = window.confirm('Você deseja associar a coluna para edição a todos os usuários da empresa?');
    if (!confirmar) return;
  
    try {
      await api.post(`/users/column-all-users/${id}/edit`, {
        empresaId: Number(user.empresa_id) // Certifique-se de que está enviando um número
      });
  
      console.log('Coluna associada para edição a todos os usuários com sucesso.');
    } catch (error) {
      console.error('Erro ao associar coluna para edição:', error);
    }
  };
  



  // const desassociarColunaParaTodosOsUsuariosVisualizacao = async (id) => {
  //   const confirmar = window.confirm('Você deseja desassociar a coluna da visualização de todos os usuários da empresa?');
  //   if (!confirmar) return;

  //   try {
  //     await axios.post(
  //       `${apiUrl}/users/column-all-users/${id}/unview`,
  //       { empresaId: Number(user.empresa_id) },
  //       { headers: { Authorization: `Bearer ${user.token}` } }
  //     );
  //     console.log('Coluna desassociada da visualização para todos os usuários com sucesso.');

  //     desassociarColunaParaTodosOsUsuariosEdicao(id)
  //   } catch (error) {
  //     console.error('Erro ao desassociar coluna da visualização:', error);
  //   }
  // };



  const desassociarColunaParaTodosOsUsuariosVisualizacao = async (id) => {
    const confirmar = window.confirm('Você deseja desassociar a coluna da visualização de todos os usuários da empresa?');
    if (!confirmar) return;
  
    try {
      await api.post(`/users/column-all-users/${id}/unview`, {
        empresaId: Number(user.empresa_id)
      });
  
      console.log('Coluna desassociada da visualização para todos os usuários com sucesso.');
  
      desassociarColunaParaTodosOsUsuariosEdicao(id);
    } catch (error) {
      console.error('Erro ao desassociar coluna da visualização:', error);
    }
  };
  

  // const desassociarColunaParaTodosOsUsuariosEdicao = async (id) => {
  //   const confirmar = window.confirm('Você deseja desassociar a coluna da edição para todos os usuários da empresa?');
  //   if (!confirmar) return;

  //   try {
  //     await axios.post(
  //       `${apiUrl}/users/column-all-users/${id}/unedit`,
  //       { empresaId: Number(user.empresa_id) },
  //       { headers: { Authorization: `Bearer ${user.token}` } }
  //     );
  //     console.log('Coluna desassociada da edição para todos os usuários com sucesso.');
  //   } catch (error) {
  //     console.error('Erro ao desassociar coluna da edição:', error);
  //   }
  // };

  const desassociarColunaParaTodosOsUsuariosEdicao = async (id) => {
    const confirmar = window.confirm('Você deseja desassociar a coluna da edição para todos os usuários da empresa?');
    if (!confirmar) return;
  
    try {
      await api.post(`/users/column-all-users/${id}/unedit`, {
        empresaId: Number(user.empresa_id)
      });
  
      console.log('Coluna desassociada da edição para todos os usuários com sucesso.');
    } catch (error) {
      console.error('Erro ao desassociar coluna da edição:', error);
    }
  };
  





  // ---------- INTERAÇÕES DE USUÁRIOS ---------
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [interactions, setInteractions] = useState([]);

  // const fetchInteractions = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/users/user-interactions`, {
  //       params: {
  //         startDate,
  //         endDate,
  //         empresaId: user.empresa_id,
  //       },
  //     });
  //     setInteractions(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar interações:', error);
  //   }
  // };

  const fetchInteractions = async () => {
    try {
      const response = await api.get(`/users/user-interactions`, {
        params: {
          startDate,
          endDate,
          empresaId: user.empresa_id,
        },
      });
      setInteractions(response.data);
    } catch (error) {
      console.error('Erro ao buscar interações:', error);
    }
  };
  


  // const exportarTabelaInteracoes = async () => {
  //   if (!interactions || interactions.length === 0) {
  //     alert('Não há dados para exportar.');
  //     return;
  //   }

  //   try {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet('Interações de Usuários');

  //     // Definir as colunas da planilha
  //     worksheet.columns = [
  //       { header: 'Usuário', key: 'username', width: 20 },
  //       { header: 'Histórico', key: 'total_history', width: 15 },
  //       { header: 'Tarefas Criadas', key: 'total_created_tasks', width: 20 },
  //       { header: 'Tarefas Finalizadas', key: 'total_completed_tasks', width: 20 },
  //       { header: 'Mensagens', key: 'total_messages', width: 15 },
  //       { header: 'Anexos', key: 'total_anexos', width: 15 },
  //       { header: 'Total de Interações', key: 'total_interactions', width: 20 },
  //     ];

  //     // Adicionar as linhas de dados
  //     interactions.forEach((user) => {
  //       worksheet.addRow({
  //         username: user.username,
  //         total_history: user.total_history,
  //         total_created_tasks: user.total_created_tasks,
  //         total_completed_tasks: user.total_completed_tasks,
  //         total_messages: user.total_messages,
  //         total_anexos: user.total_anexos,
  //         total_interactions: user.total_interactions,
  //       });
  //     });

  //     // Gerar o arquivo Excel e baixar
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });

  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = `Interacoes_Usuarios_${new Date().toISOString().split('T')[0]}.xlsx`;
  //     link.click();
  //   } catch (error) {
  //     console.error('Erro ao exportar tabela:', error);
  //     alert('Erro ao exportar tabela. Tente novamente.');
  //   }
  // };




  const exportarTabelaInteracoes = async () => {
    if (!interactions || interactions.length === 0) {
      alert('Não há dados para exportar. Buscando informações...');
      try {
        await fetchInteractions(); // Garante que os dados estejam atualizados
      } catch (error) {
        console.error('Erro ao buscar interações:', error);
        alert('Erro ao buscar dados para exportação.');
        return;
      }
    }
  
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Interações de Usuários');
  
      // Definir as colunas da planilha
      worksheet.columns = [
        { header: 'Usuário', key: 'username', width: 20 },
        { header: 'Histórico', key: 'total_history', width: 15 },
        { header: 'Tarefas Criadas', key: 'total_created_tasks', width: 20 },
        { header: 'Tarefas Finalizadas', key: 'total_completed_tasks', width: 20 },
        { header: 'Mensagens', key: 'total_messages', width: 15 },
        { header: 'Anexos', key: 'total_anexos', width: 15 },
        { header: 'Total de Interações', key: 'total_interactions', width: 20 },
      ];
  
      // Adicionar as linhas de dados
      interactions.forEach((user) => {
        worksheet.addRow({
          username: user.username,
          total_history: user.total_history,
          total_created_tasks: user.total_created_tasks,
          total_completed_tasks: user.total_completed_tasks,
          total_messages: user.total_messages,
          total_anexos: user.total_anexos,
          total_interactions: user.total_interactions,
        });
      });
  
      // Gerar o arquivo Excel e baixar
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Interacoes_Usuarios_${new Date().toISOString().split('T')[0]}.xlsx`;
      link.click();
    } catch (error) {
      console.error('Erro ao exportar tabela:', error);
      alert('Erro ao exportar tabela. Tente novamente.');
    }
  };
  












  // const fetchBoasVindas = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/notifications/`, {
  //       params: { empresa_id: user.empresa_id },
  //     });
  //     if (response.data) {
  //       setBoasVindasHtml(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Erro ao buscar mensagem de boas-vindas:', error);
  //   }
  // };





  const fetchBoasVindas = async () => {
    try {
      const response = await api.get(`/notifications/`, {
        params: { empresa_id: user.empresa_id },
      });
  
      if (response.data) {
        setBoasVindasHtml(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao buscar mensagem de boas-vindas:', error);
    }
  };

  


  
  // const saveBoasVindas = async () => {
  //   try {
  //     const response = await axios.put(`${apiUrl}/notifications`, {
  //       empresa_id: user.empresa_id,
  //       message: boasVindasHtml,
  //     });
  //     //console.log('Mensagem de boas-vindas atualizada:', response.data);
  //     setModalBoasVindas(false);
  //   } catch (error) {
  //     console.error('Erro ao salvar mensagem de boas-vindas:', error);
  //   }
  // };


  const saveBoasVindas = async () => {
    try {
      const response = await api.put(`/notifications`, {
        empresa_id: user.empresa_id,
        message: boasVindasHtml,
      });
  
      //console.log('Mensagem de boas-vindas atualizada:', response.data);
      setModalBoasVindas(false);
    } catch (error) {
      console.error('Erro ao salvar mensagem de boas-vindas:', error);
    }
  };
  

  
  useEffect(() => {
    if (modalBoasVindas) {
      fetchBoasVindas();
    }
  }, [modalBoasVindas]);
  

  // Sempre que o usuário digitar, atualizamos também a pré-visualização transformada
const handleHtmlChange = (e) => {
  const originalHtml = e.target.value;
  setBoasVindasHtml(originalHtml);
  // Aplica a transformação: todas as classes e ids serão prefixadas,
  // e o resultado será renderizado dentro do container único
  const transformed = transformHtmlToUnique(originalHtml);
  setPreviewHtml(transformed);
};




  return (
    <div className="users-page-container">
      <Header />


      <div className="tools-parametros-container">
        <button className="btn-select-edit-parameter" onClick={showModalEtiquetas}>Etiquetas</button>
        <button className="btn-select-edit-parameter" onClick={showModalOrigens}>Origens</button>
        <button className="btn-select-edit-parameter" onClick={showModalColunas}>Colunas</button>
        <button className="btn-select-edit-parameter" onClick={showModalProdutos}>Produtos</button>
        <button className="btn-select-edit-parameter" onClick={showModalCores}>Cores</button>
        <button className="btn-select-edit-parameter" onClick={showModalStatus}>Status</button>
        <button className="btn-select-edit-parameter" onClick={() => exportarTabelasExcel()}>Backup Tabelas</button>
        <button className="btn-select-edit-parameter" onClick={() => engajamentoUsers()}>Engajamento de Usuários</button>
        <button className="btn-select-edit-parameter" onClick={() => setModalBoasVindas(true)}>Boas-Vindas</button>




        {/* {user && getAccessLevel('exportExceljjjjjjj') &&
          <RiFileExcel2Line style={{ display: openCloseFilterModal || openCloseSelectAfilhadosModal || openCloseSelectDateModal || openCloseSearchModal ? 'none' : '' }} onClick={() => exportarTabelasExcel()} className='afilhados-icon-open-close' />
        } */}


      </div>
      {modalEngajamentoUsers && (
        <div className='engajamento-container'>
          <div>
            <label>Data Inicial:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label>Data Final:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={fetchInteractions}>Buscar Interações</button>
            <button onClick={exportarTabelaInteracoes}>Exportar Excel</button>

          </div>



          <div class="table-angajamento-users-wrapper">
            <table class="table-angajamento-users">
              <thead>
                <tr>
                  <th>Usuário</th>
                  <th>Histórico</th>
                  <th>Tarefas Criadas</th>
                  <th>Tarefas Finalizadas</th>
                  <th>Mensagens</th>
                  <th>Anexos</th>
                  <th>Total de Interações</th>
                </tr>
              </thead>
              <tbody>
                {interactions.map((user) => (
                  <tr key={user.user_id}>
                    <td>{user.username}</td>
                    <td>{user.total_history}</td>
                    <td>{user.total_created_tasks}</td>
                    <td>{user.total_completed_tasks}</td>
                    <td>{user.total_messages}</td>
                    <td>{user.total_anexos}</td>
                    <td>{user.total_interactions}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      )}





      {creatingColumn && (
        <div className="parameter-modal-etiqueta">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Criar Nova Coluna</label>
            </div>
            <div className="parameter-body">
              <label>Nome:</label>
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <label>Ordem de Exibição:</label>
              <input
                type="number"
                value={newDisplayOrder}
                onChange={(e) => setNewDisplayOrder(e.target.value)}
              />
              <label>Descrição:</label>
              <input
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />
              <label>Setor:</label>
              <input
                type="text"
                value={newSetor}
                onChange={(e) => setNewSetor(e.target.value)}
              />
              <label>
                <input
                  type="checkbox"
                  checked={clientePodeVer}
                  onChange={(e) => setClientePodeVer(e.target.checked)}
                />
                Cliente pode ver esta coluna
              </label>
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={closeCreateColumnModal}>Cancelar</button>
              <button className="btn-add-parameter" onClick={createColuna}>Salvar</button>
            </div>
          </div>
        </div>
      )}





      {editingColumn && (
        <div className="parameter-modal-etiqueta">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Editar Coluna</label>
            </div>
            <div className="parameter-body">
              <label className='title-edite-column-input'>Nome:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />

              <label className='title-edite-column-input'>Ordem de Exibição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="number"
                value={newDisplayOrder}
                onChange={(e) => setNewDisplayOrder(e.target.value)}
              />

              <label className='title-edite-column-input'>Descrição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />

              <label className='title-edite-column-input'>Setor:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newSetor}
                onChange={(e) => setNewSetor(e.target.value)}
              />

              <label>
                <input
                  type="checkbox"
                  checked={clientePodeVer}
                  onChange={(e) => setClientePodeVer(e.target.checked)}
                  className="checkbox-cliente"
                />
                Cliente pode ver esta coluna
              </label>


              <button className="btn-associar-coluna" onClick={() => associarColunaParaTodosOsUsuarios(columnID)}>Associar esta Coluna para que todos os usuários possam ver</button>
              <button className="btn-associar-coluna" onClick={() => associarColunaParaTodosOsUsuariosEditar(columnID)}>Associar esta Coluna para que todos os usuários possam editar</button>

              <button className="btn-desassociar-coluna" onClick={() => desassociarColunaParaTodosOsUsuariosVisualizacao(columnID)}>Desassociar esta Coluna para todos os usuários</button>



            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={() => setEditingColumn(null)}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveColumnEdits}>Salvar</button>
            </div>
          </div>
        </div>
      )}

      {/* {editingColumn && (
        <div className="parameter-modal-etiqueta">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Editar Coluna</label>
            </div>
            <div className="parameter-body">
              <label>Nome:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />

              <label>Ordem de Exibição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="number"
                value={newDisplayOrder}
                onChange={(e) => setNewDisplayOrder(e.target.value)}
              />

              <label>Descrição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />

              <label>Setor:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newSetor}
                onChange={(e) => setNewSetor(e.target.value)}
              />
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={() => setEditingColumn(null)}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveColumnEdits}>Salvar</button>
            </div>
          </div>
        </div>
      )} */}




      {editingEtiqueta && (
        <div className="parameter-modal-etiqueta">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Editar Etiqueta</label>
            </div>
            <div className="parameter-body">
              <label className='title-select-color-etiqueta-selector'>Descrição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />

              <label className='title-select-color-etiqueta-selector'>Ordem:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="number"
                value={newOrder}
                onChange={(e) => setNewOrder(e.target.value)}
              />

              <label className='title-select-color-etiqueta-selector'>Selecione a Cor:</label>
              <div className="color-options">
                {availableColors.map((color) => (
                  <div
                    key={color}
                    className={`color-option ${color === selectedColor ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedColor(color)}
                  ></div>
                ))}
              </div>
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={() => setEditingEtiqueta(null)}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveEtiquetaEdits}>Salvar</button>
            </div>
          </div>
        </div>
      )}







      {modalCores && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Cores</label>
            </div>
            <div className="parameter-body">
              {listaCores?.map((item) => (
                <div className="item-list-parameter" key={item.id} style={{ borderLeft: `10px solid ${item.color_code}` }}>
                  <label className="label-item-parameter">{item.name}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeCor(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeCor(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalCores}>Fechar</button>
              <button className="btn-add-parameter" onClick={createCor}>Adicionar</button>
            </div>
          </div>
        </div>
      )}





      {editingCor && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Editar Cor</label>
            </div>
            <div className="parameter-body">
              <label>Nome:</label>
              <input
                type="text"
                value={newColorName}
                onChange={(e) => setNewColorName(e.target.value)}
              />

              <label>Selecione a Cor:</label>
              <div className="color-options">
                {availableColors.map((color) => (
                  <div
                    key={color}
                    className={`color-option ${color === selectedColorForEdit ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setSelectedColorForEdit(color)}
                  ></div>
                ))}
              </div>
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={() => setEditingCor(null)}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveCorEdits}>Salvar</button>
            </div>
          </div>
        </div>
      )}









      {newColor && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Criar Nova Cor</label>
            </div>
            <div className="parameter-body">
              <label>Nome:</label>
              <input
                type="text"
                value={newColorName}
                onChange={(e) => setNewColorName(e.target.value)}
              />

              <label>Selecione a Cor:</label>
              <div className="color-options">
                {availableColors.map((color) => (
                  <div
                    key={color}
                    className={`color-option ${color === newSelectedColor ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setNewSelectedColor(color)}
                  ></div>
                ))}
              </div>
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={() => setNewColor(null)}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveNewCor}>Salvar</button>
            </div>
          </div>
        </div>
      )}




      {/* 
      {modalEtiquetas && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Etiquetas</label>
            </div>
            <div className="parameter-body">
              {listaEtiquetas?.map((item) => (
                <div className="item-list-parameter" key={item.id}>
                  <label className="label-item-parameter">{item.description}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeEtiqueta(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeEtiqueta(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalEtiquetas}>Fechar</button>
              <button className="btn-add-parameter" onClick={createEtiqueta}>Adicionar</button>
            </div>
          </div>
        </div>
      )} */}

      {newEtiqueta && (
        <div className="parameter-modal-etiqueta">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Criar Nova Etiqueta</label>
            </div>
            <div className="parameter-body">
              <label className='title-select-color-etiqueta-selector'>Descrição:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="text"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
              />

              <label className='title-select-color-etiqueta-selector'>Ordem:</label>
              <input
                className='input-select-color-etiqueta-selector'
                type="number"
                value={newOrder}
                onChange={(e) => setNewOrder(e.target.value)}
              />

              <label className='title-select-color-etiqueta-selector'>Selecione a Cor:</label>
              <div className="color-options">
                {availableColors.map((color) => (
                  <div
                    key={color}
                    className={`color-option ${color === newSelectedColor ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => setNewSelectedColor(color)}
                  ></div>
                ))}
              </div>
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={hideCreateEtiquetaModal}>Cancelar</button>
              <button className="btn-add-parameter" onClick={saveNewEtiqueta}>Salvar</button>
            </div>
          </div>
        </div>
      )}


      {modalEtiquetas && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Etiquetas</label>
            </div>
            <div className="parameter-body">
              {listaEtiquetas?.map((item) => (
                <div className="item-list-parameter" key={item.id} style={{ borderLeft: `10px solid ${item.color}` }}>
                  <label className="label-item-parameter">{item.order} - {item.description}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeEtiqueta(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeEtiqueta(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalEtiquetas}>Fechar</button>
              <button className="btn-add-parameter" onClick={showCreateEtiquetaModal}>Adicionar</button>

            </div>
          </div>
        </div>
      )}


      {modalOrigens && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Origens</label>
            </div>
            <div className="parameter-body">
              {listaOrigens?.map((item) => (
                <div className="item-list-parameter" key={item.id}>
                  <label className="label-item-parameter">{item.name}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeOrigem(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeOrigem(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalOrigens}>Fechar</button>
              <button className="btn-add-parameter" onClick={createOrigem}>Adicionar</button>
            </div>
          </div>
        </div>
      )}
      {/* 
      {modalColunas && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Colunas</label>
            </div>
            <div className="parameter-body">
              {listaColunas?.map((item) => (
                <div className="item-list-parameter" key={item.id}>
                  <label className="label-item-parameter">{item.name}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeColuna(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeColuna(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalColunas}>Fechar</button>
              <button className="btn-add-parameter" onClick={createColuna}>Adicionar</button>
            </div>
          </div>
        </div>
      )} */}

      {modalColunas && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Colunas</label>
            </div>
            <div className="parameter-body">
              {listaColunas?.map((item) => (
                <div className="item-list-parameter" key={item.id}>
                  <label className="label-item-parameter">{item.display_order} - {item.name}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeColuna(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editColumn(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalColunas}>Fechar</button>

              <button className="btn-add-parameter" onClick={openCreateColumnModal}>Adicionar</button>



            </div>
          </div>
        </div>
      )}

      {modalProdutos && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Produtos</label>
            </div>
            <div className="parameter-body">
              {listaProdutos?.map((item) => (
                <div className="item-list-parameter" key={item.id}>
                  <label className="label-item-parameter">{item.name}</label>
                  <div className="btns-item-list">
                    <button className="btn-remove-item-list" onClick={() => removeProduto(item.id)}>Remover</button>
                    <button className="btn-edite-item-list" onClick={() => editeProduto(item)}>Editar</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalProdutos}>Fechar</button>
              <button className="btn-add-parameter" onClick={createProduto}>Adicionar</button>
            </div>
          </div>
        </div>
      )}


      {modalStatus && (
        <div className="parameter-modal">
          <div className="parameter-container">
            <div className="parameter-header">
              <label className="parameter-title">Status X Coluna</label>
            </div>
            <div className="parameter-body">

              <label className="label-item-parameter-description">Quando Status Vendido ir para a Coluna com seguinte nome:</label>
              <div className="item-list-parameter" >
                <label className="label-item-parameter">{colunaStatusVendido}</label>
                <div className="btns-item-list">
                  <button className="btn-edite-item-list" onClick={() => editeColunaStatusVendido()}>Editar</button>
                </div>
              </div>

              <label className="label-item-parameter-description">Quando Status Perdido ir para a Coluna com seguinte nome:</label>
              <div className="item-list-parameter" >
                <label className="label-item-parameter">{colunaStatusPerdido}</label>
                <div className="btns-item-list">
                  <button className="btn-edite-item-list" onClick={() => editeColunaStatusPerdido()}>Editar</button>
                </div>
              </div>


              <label className="label-item-parameter-description">Quando Status Arquivado ir para a Coluna com seguinte nome:</label>
              <div className="item-list-parameter" >
                <label className="label-item-parameter">{colunaStatusArquivado}</label>
                <div className="btns-item-list">
                  <button className="btn-edite-item-list" onClick={() => editeColunaStatusArquivado()}>Editar</button>
                </div>
              </div>


              <label className="label-item-parameter-description">Quando Criar Pedido ir para a Coluna com seguinte nome:</label>
              <div className="item-list-parameter" >
                <label className="label-item-parameter">{colunaStatusPedido}</label>
                <div className="btns-item-list">
                  <button className="btn-edite-item-list" onClick={() => editeColunaStatusPedido()}>Editar</button>
                </div>
              </div>



            </div>
            <div className="parameter-footer">
              <button className="btn-close-parameter" onClick={showModalStatus}>Fechar</button>
            </div>
          </div>
        </div>
      )}



{modalBoasVindas && (
  <div className="parameter-modal">
    <div className="parameter-container-html">
      <div className="parameter-header">
        <label className="parameter-title">Boas-Vindas</label>
      </div>
      <div
        className="parameter-body"
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          alignItems: 'flex-start'
        }}
      >
        {/* Campo para digitar o HTML (lado esquerdo) */}
        <textarea
          value={boasVindasHtml}
          onChange={handleHtmlChange}
          placeholder="Insira o texto HTML aqui..."
          rows="10"
          style={{
            width: '50%',
            height: 'calc(100vh - 250px)',
            resize: 'none'
          }}
        />
        {/* Pré-visualização do HTML transformado (lado direito) */}
        <div
          id="boas-vindas-container"
          style={{
            width: '50%',
            border: '1px solid #ccc',
            padding: '10px',
            height: 'calc(100vh - 250px)',
            overflowY: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: previewHtml }}
        />
      </div>
      <div className="parameter-footer">
        <button className="btn-close-parameter" onClick={() => setModalBoasVindas(false)}>
          Fechar
        </button>
        <button className="btn-add-parameter" onClick={saveBoasVindas}>
          Salvar
        </button>
      </div>
    </div>
  </div>
)}




    </div>
  );
}

export default ColumnsPage;
