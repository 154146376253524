import React, { useState, useEffect, useRef } from 'react';

import Calendar from '../../assets/calendar.png'

import Hamburger from '../../assets/hamburger.png'

// STYLE
import './style.css';

// REACT ICONS
import { FaBars } from 'react-icons/fa';
import { MdOutlineCalendarMonth, MdLens, MdNotifications, MdDateRange, MdAddTask } from 'react-icons/md';

import { FaChartLine, FaMedal } from 'react-icons/fa';


import { TbMessageDots } from "react-icons/tb";


import { useNavigate } from 'react-router-dom';

import Logo from '../../assets/logo-suite-flow.ico';

import { useUser } from '../../contexts/userContext';
import { useCard } from '../../contexts/cardContext';
import { useColumns } from '../../contexts/columnsContext';

import { format, parseISO } from 'date-fns';

import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import PreviewCard from '../../components/PreviewCard';

import UpdateCard from '../forms/UpdateCard';
import Historic from '../forms/Historic';
import Tarefas from '../forms/Tarefas';
import Compartilhar from '../forms/Compartilhar';
import ModuloEsquadrias from '../forms/ModuloEsquadrias';
import Loading from '../Loading';
import Messenger from '../Messenger';
import Anexos from '../Anexos';
import Avatar from '../Avatar';
import Calendario from '../Calendario';
import CustomModule from '../DynamicForm/CustomModule';
import ModuloPedidos from '../ModuloPedido';
import TVSlider from '../TVSlider';

import img01 from '../../assets/login-page-img02.png'

import notificationAudio from '../../assets/audio/notification.wav'

import Faktory from '../Faktory';
import api from '../../services/api';



function Header() {

  const { user, clearUserContext, setOpenCloseImportExcelEntidades, setOpenCloseImportExcelSuiteFlow, themeDark, getAccessLevel, openCloseModalAvatar, setOpenCloseModalAvatar, userAvatar, setUserAvatar, openCloseCustomModule, setOpenCloseCustomModule } = useUser();
  const { setEditableColumns, setColumns, setColumnsUser, setSelectedAfilhados, dataInicial, setDataInicial, dataFinal, setDataFinal, showFaktoryModule, handleLocalSearch, setSelectedEtiqueta} = useColumns();

  const { setCurrentCardData, openCloseUpdateCard,
    setOpenCloseUpdateCard, listNotifications,
    setListNotifications, currentCardData,
    openCloseHistoricModal, setOpenCloseHistoricModal,
    openCloseTarefasModal, setOpenCloseTarefasModal,
    openCloseCompartilharModal, setOpenCloseCompartilharModal,
    tarefas, setTarefas,
    openCloseModuloEsquadriasModal, setCards, setPreviewSearchCards, setOpenCloseModalVendaPerdida,
    openCloseModalMessenger, setOpenCloseModalMessenger,
    openCloseAnexosModal, setOpenCloseAnexosModal,
    openClosePedidosModal, setOpenClosePedidosModal
  } = useCard();

  const [showMenuUser, setShowMenuUser] = useState(false);
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [totalVendas, setTotalVendas] = useState(0);
  const [totalVendasGrupo, setTotalVendasGrupo] = useState(0);
  const [metaUser, setMetaUser] = useState(0);
  const [metaUserGrupo, setMetaUserGrupo] = useState(0);
  const [numberNotifications, setNumberNotifications] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);
  const [renderedNotifications, setRenderedNotifications] = useState([]);
  const [itemsToRender, setItemsToRender] = useState(15);
  const notificationsRef = useRef(null);
  const navigate = useNavigate();










  /// ---------------- messenger ----------------

  // Adicione um estado para armazenar a quantidade de mensagens não lidas
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);


  // const fetchUnreadMessagesCount = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/card/total-unread-messages-count/${user.id}`);
  //     setUnreadMessagesCount(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar contagem de mensagens não lidas', error);
  //   }
  // };


  const fetchUnreadMessagesCount = async () => {
    try {
      const response = await api.get(`/card/total-unread-messages-count/${user.id}`);
      setUnreadMessagesCount(response.data);
    } catch (error) {
      console.error("Erro ao buscar contagem de mensagens não lidas:", error);
    }
  };
  
  useEffect(() => {

    if (user) {
      setUserAvatar(user.avatar);
    }

  }, [user]);


  useEffect(() => {

    if (!user)
      return

    let interval;
    if (!openCloseModalMessenger) {
      fetchUnreadMessagesCount();
      interval = setInterval(() => {
        fetchUnreadMessagesCount();
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [user, openCloseModalMessenger]);



  useEffect(() => {
    if (unreadMessagesCount > 0) {
      const audio = new Audio(notificationAudio);
      audio.play();
    }
  }, [unreadMessagesCount]);







  const closeModal = () => {
    setOpenCloseModalMessenger(false)
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    clearUserContext();
    setCards([]);
    setColumns([]);
    setPreviewSearchCards([]);
    localStorage.clear();
    sessionStorage.clear();
    setColumnsUser([]);
    setEditableColumns([]);
    setSelectedAfilhados([]);
    navigate('/');
    setDataInicial(null)
    setDataFinal(null);
    handleLocalSearch('');
    setSelectedEtiqueta('')
  };

  function openLeftMenu() {
    setShowLeftMenu(!showLeftMenu);
  }

  function usersPage() {
    navigate('/users');
  }



  function tvSliderPage() {
    navigate('/slider');
  }

  function projetosPage() {
    navigate('/projetos');
  }

  function dashboardPage() {
    navigate('/dashboard');
  }

  function participantesPage() {
    navigate('/participantes');
  }

  function pipelinePage() {
    navigate('/home');
  }

  function processColumnsPage() {
    navigate('/process');
  }

  function PCP() {
    navigate('/pcp');
  }

  function openCloseInfosUser() {
    setShowMenuUser(!showMenuUser);
  }

  // const getVendasDoMesAtual = async () => {
  //   try {
  //     const entityId = user.id;
  //     const response = await axios.get(`${apiUrl}/card/sales/total/${entityId}`);
  //     setTotalVendas(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar total de vendas', error);
  //   }
  // };

  const getVendasDoMesAtual = async () => {
    try {
      const entityId = user.id;
      const response = await api.get(`/card/sales/total/${entityId}`);
      setTotalVendas(response.data);
    } catch (error) {
      console.error("Erro ao buscar total de vendas:", error);
    }
  };
  

  // const getVendasDosAfilhadosDoMesAtual = async () => {
  //   try {
  //     const entityId = user.id;
  //     const response = await axios.get(`${apiUrl}/card/sales/total-afilhados/${entityId}`);
  //     setTotalVendasGrupo(response.data);
  //   } catch (error) {
  //     console.error('Erro ao buscar total de vendas dos afilhados', error);
  //   }
  // };

  const getVendasDosAfilhadosDoMesAtual = async () => {
    try {
      const entityId = user.id;
      const response = await api.get(`/card/sales/total-afilhados/${entityId}`);
      setTotalVendasGrupo(response.data);
    } catch (error) {
      console.error("Erro ao buscar total de vendas dos afilhados:", error);
    }
  };
  

  useEffect(() => {
    if (user) {
      setMetaUser(user.meta_user);
      setMetaUserGrupo(user.meta_grupo);
    }
    if (showMenuUser) {
      getVendasDoMesAtual();
      getVendasDosAfilhadosDoMesAtual();
    }
  }, [showMenuUser, user]);

  const percentualProgresso = (totalVendas / metaUser) * 100;
  const percentualProgressoGrupo = (totalVendasGrupo / metaUserGrupo) * 100;

  // const fetchOverdueTasks = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/card/tasks/overdue/${user.id}`);
  //     setNumberNotifications(response.data.length);
  //     setListNotifications(response.data);
  //     setAllNotifications(response.data);
  //     setRenderedNotifications(response.data.slice(0, itemsToRender));
  //   } catch (error) {
  //     console.error('Erro ao buscar tarefas vencidas', error);
  //     setNumberNotifications(0);
  //   }
  // };

  const fetchOverdueTasks = async () => {
    try {
      const response = await api.get(`/card/tasks/overdue/${user.id}`);
      setNumberNotifications(response.data.length);
      setListNotifications(response.data);
      setAllNotifications(response.data);
      setRenderedNotifications(response.data.slice(0, itemsToRender));
    } catch (error) {
      console.error("Erro ao buscar tarefas vencidas:", error);
      setNumberNotifications(0);
    }
  };
  

  useEffect(() => {
    if (user && user.id) {
      fetchOverdueTasks();
    }
  }, [user, tarefas]);

  function getCardData(card) {
    setCurrentCardData(card);
    setOpenCloseUpdateCard(!openCloseUpdateCard);
  }

  function formatDate(dateString) {
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yy');
  }

  const loadMoreNotifications = () => {
    const nextItemsToRender = itemsToRender + 15;
    const newRenderedNotifications = allNotifications.slice(0, nextItemsToRender);
    setRenderedNotifications(newRenderedNotifications);
    setItemsToRender(nextItemsToRender);
  };

  const handleScroll = () => {
    const container = notificationsRef.current;
    if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
      loadMoreNotifications();
    }
  };

  useEffect(() => {
    const container = notificationsRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [renderedNotifications]);

  useEffect(() => {
    if (showNotifications) {
      setRenderedNotifications(allNotifications.slice(0, itemsToRender));
    }
  }, [showNotifications, allNotifications, itemsToRender]);




  const [showCalendar, setShowCalendar] = useState(false);




  const [isVisible, setIsVisible] = useState(false);
  const [topCards, setTopCards] = useState([]);

  // useEffect(() => {
  //   if (user) {
  //     const fetchTopCards = async () => {
  //       try {
  //         const response = await axios.get(`${apiUrl}/process-columns/top-10-potential-sales/${user.id}`);
  //         setTopCards(response.data);
  //         //console.log(response.data)
  //       } catch (error) {
  //         console.error("Error fetching top potential sales cards", error);
  //       }
  //     };

  //     fetchTopCards();
  //   }
  // }, [user]);

  useEffect(() => {
    if (user) {
      const fetchTopCards = async () => {
        try {
          const response = await api.get(`/process-columns/top-10-potential-sales/${user.id}`);
          setTopCards(response.data);
          // console.log(response.data);
        } catch (error) {
          console.error("Erro ao buscar os 10 principais cards de vendas potenciais:", error);
        }
      };
  
      fetchTopCards();
    }
  }, [user]);
  

  const getMedalIcon = (index) => {
    if (index === 0) return <FaMedal className="gold-medal" />;
    if (index === 1) return <FaMedal className="silver-medal" />;
    if (index === 2) return <FaMedal className="bronze-medal" />;
    return null;
  };







  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '' });
const [tooltipTimeout, setTooltipTimeout] = useState(null);
const [hideTimeout, setHideTimeout] = useState(null);

const showTooltip = (e, text) => {
  const rect = e.target.getBoundingClientRect();

  // Cancela timeouts anteriores
  if (tooltipTimeout) clearTimeout(tooltipTimeout);
  if (hideTimeout) clearTimeout(hideTimeout);

  // Aguarda 3 segundos antes de exibir a tooltip
  const timeoutId = setTimeout(() => {
    setTooltip({
      visible: true,
      x: rect.left + rect.width / 2, // Centraliza no botão
      y: rect.top - 10, // Posiciona acima do botão
      text,
    });

    // Define um timeout para esconder a tooltip após 3 segundos
    const hideId = setTimeout(() => {
      setTooltip({ visible: false, x: 0, y: 0, text: '' });
    }, 3000);

    setHideTimeout(hideId);
  }, 1000);

  setTooltipTimeout(timeoutId);
};

const hideTooltip = () => {
  // Cancela qualquer timeout ativo
  if (tooltipTimeout) clearTimeout(tooltipTimeout);
  if (hideTimeout) clearTimeout(hideTimeout);

  setTooltip({ visible: false, x: 0, y: 0, text: '' });
};


const [currentQuote, setCurrentQuote] = useState('');


  // Array de frases motivacionais
  const motivationalQuotes = [
    "A persistência é o caminho do êxito. — Charles Chaplin",
    "Conhece-te a ti mesmo e conhecerás o cliente. — Sócrates",
    "O desejo é o começo de toda conquista. — Napoleão Hill",
    "Nada é suficientemente difícil para quem tem força de vontade. — Cícero",
    "Vender é ouvir antes de falar. — Epicteto",
    "A excelência não é um feito, mas um hábito. — Aristóteles",
    "Tudo que um homem pode imaginar, outro pode realizar. — Júlio Verne",
    "Transforme obstáculos em degraus para seu sucesso. — Friedrich Nietzsche",
    "O homem é persuadido mais pelo coração do que pela razão. — Blaise Pascal",
    "A retórica bem aplicada pode transformar um não em um sim. — Aristóteles",
    "A melhor maneira de prever o futuro das suas vendas é criá-lo. — Peter Drucker",
    "O convencimento começa quando entendemos a dor do cliente. — Daniel Kahneman",
    "Simplicidade é o último grau de sofisticação. — Leonardo da Vinci",
    "O segredo das grandes vendas está na arte de perguntar. — Sócrates",
    "Quem conhece os outros é sábio; quem conhece a si mesmo é iluminado. — Lao-Tsé",
    "A necessidade molda a vontade de compra. — Platão",
    "Quem deseja vencer, deve aprender a lutar. — Sun Tzu",
    "A paciência é amarga, mas seu fruto é doce. — Aristóteles",
    "Fracasso não é o oposto do sucesso; é parte do caminho. — Arianna Huffington",
    "O sucesso não vem da força, mas da resiliência. — Confúcio",
    "A pedra fundamental do sucesso é a determinação. — Thomas Jefferson",
    "Se você quer algo, vá e faça. Oportunidades não esperam. — Thomas Edison",
    "Tudo parece impossível até que seja feito. — Nelson Mandela",
    "Se tropeçar, levante-se. Cada queda ensina algo. — Friedrich Nietzsche",
    "A melhor propaganda é feita por clientes satisfeitos. — Philip Kotler",
    "Gente compra de gente, não de empresas. — Simon Sinek",
    "O segredo das vendas está em ouvir mais do que falar. — Dale Carnegie",
    "Empatia vende mais que qualquer argumento técnico. — Daniel Goleman",
    "Quem tem um 'porquê' forte, enfrenta qualquer 'como'. — Viktor Frankl",
    "O cliente sempre percebe quando a venda é sincera. — Seth Godin",
    "Vender é sobre resolver problemas, não sobre empurrar produtos. — Steve Jobs",
    "As pessoas não compram produtos, compram transformação. — Tony Robbins",
    "Ninguém resiste a um vendedor que realmente acredita no que vende. — Henry Ford",
    "As oportunidades multiplicam-se à medida que são aproveitadas. — Sun Tzu",
    "Cada crise traz consigo a semente de uma grande oportunidade. — Albert Einstein",
    "O verdadeiro vendedor cria o desejo antes mesmo da necessidade. — David Ogilvy",
    "A inovação distingue um líder de um seguidor. — Steve Jobs",
    "O melhor momento para vender é agora. — Confúcio",
    "Quem não arrisca, não vende. — Napoleão Bonaparte",
    "A intuição é uma poderosa ferramenta de vendas. — Carl Jung",
    "Não espere as oportunidades aparecerem, crie-as. — George Bernard Shaw",
    "O segredo da genialidade está na ousadia de fazer diferente. — Voltaire",
    "Sua atitude determina seu resultado. — Napoleon Hill",
    "O que diferencia um vencedor é a forma como ele lida com desafios. — Bruce Lee",
    "Se você acredita que pode, já está na metade do caminho. — Theodore Roosevelt",
    "A mente é tudo. Você se torna aquilo que pensa. — Buda",
    "O sucesso começa quando você decide sair da zona de conforto. — Tony Robbins",
    "A energia que você transmite reflete nas suas vendas. — Daniel Goleman",
    "O entusiasmo é contagiante. Contamine seus clientes! — Ralph Waldo Emerson",
    "O primeiro passo para o sucesso é acreditar nele. — Norman Vincent Peale",
    "Cada cliente é um mundo novo a ser descoberto. — Dale Carnegie",
    "A disciplina é a ponte entre metas e conquistas. — Jim Rohn",
    "Repetição cria excelência. — Bruce Lee",
    "O sucesso vem para aqueles que se preparam todos os dias. — Confúcio",
    "Vendas são um jogo de consistência e melhoria contínua. — Kaizen",
    "Não há atalhos para o sucesso, apenas trabalho consistente. — Malcolm Gladwell",
    "Pequenas melhorias diárias geram resultados extraordinários. — James Clear",
    "Seu futuro de vendas depende das ações que toma hoje. — John Maxwell",
    "O sucesso não acontece por acaso, mas por esforço deliberado. — Angela Duckworth",
    "A jornada da excelência começa com pequenos passos. — Seneca",
    "O trabalho árduo vence o talento quando o talento não trabalha duro. — Tim Notke",
    "O sucesso não é um destino, mas uma jornada. — Ralph Waldo Emerson",
    "Não se trata apenas de vender, mas de impactar vidas. — Seth Godin",
    "Cada venda bem-feita é um degrau na escada do sucesso. — Benjamin Franklin",
    "Os campeões não esperam as oportunidades, eles as criam. — Michael Jordan",
    "O tempo investido em aprender a vender é um dos mais valiosos. — Warren Buffett",
    "Grandes resultados vêm de grandes esforços. — John Wooden",
    "O conhecimento é o ativo mais valioso para um vendedor. — Francis Bacon",
    "O segredo não está no que você vende, mas em como você vende. — Guy Kawasaki",
    "Vender é plantar hoje para colher amanhã. — Dale Carnegie",
    "Não há vendas sem fechamento. — Grant Cardone",
    "Seja direto, objetivo e conquiste o cliente. — Jack Welch",
    "Dê ao cliente um motivo irresistível para dizer sim. — Chris Voss",
    "Venda é um jogo de paciência e estratégia. — Sun Tzu",
    "Quem tem propósito sempre fecha a venda. — Victor Hugo"
  ];
  


   // Função para gerar uma frase aleatória
   const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * motivationalQuotes.length);
    return motivationalQuotes[randomIndex];
  };

  // Atualiza a frase a cada 5 segundos
  useEffect(() => {
    setCurrentQuote(getRandomQuote()); // Define a primeira frase ao carregar
    const interval = setInterval(() => {
      setCurrentQuote(getRandomQuote());
    }, 300000);

    return () => clearInterval(interval);
  }, []);


  return (

    <header className="header-container" >

      {openCloseModalAvatar &&
        <Avatar />
      }


      <div className='header-menu-left' onClick={() => openLeftMenu()}>
        <FaBars onMouseEnter={(e) => showTooltip(e, 'Menu')} onMouseLeave={hideTooltip} />
          {/* <img className='hamburger' src={Hamburger} onMouseEnter={(e) => showTooltip(e, 'Menu')} onMouseLeave={hideTooltip}></img> */}


        <span className="motivational-quote">{currentQuote}</span>

      </div>
      <div className='header-logo-center' onClick={() => pipelinePage()}>
        <label onMouseEnter={(e) => showTooltip(e, 'Voltar para Home')} onMouseLeave={hideTooltip} className='header-logo-center-label'>SyncsCRM</label>
      </div>
      <div className='header-menu-right'>
        {/* <MdOutlineCalendarMonth className='icon-card-potencial-header' onClick={() => setShowCalendar(true)} /> */}

        <img
        onMouseEnter={(e) => showTooltip(e, 'Abrir Calendario')} onMouseLeave={hideTooltip}
          src={require('../../assets/calendar.png')}
          alt="Calendário"
          className="img-card-potencial-header"
          onClick={() => setShowCalendar(true)}
        />
        {/* <FaChartLine className='icon-card-potencial-header' onClick={() => setIsVisible(true)} /> */}
        <MdNotifications onMouseEnter={(e) => showTooltip(e, 'Tarefas Pendentes')} onMouseLeave={hideTooltip} className='icon-notification-header' onClick={() => setShowNotifications(!showNotifications)} />
        <div style={{ display: listNotifications.length > 0 ? 'none' : 'none' }} className='number-notifications-header' >{numberNotifications}</div>
        <MdLens style={{ display: listNotifications.length > 0 ? '' : 'none' }} className='icons-number-notifications-header' onClick={() => setShowNotifications(!showNotifications)} />
      </div>


      {isVisible &&

        <div className='cards-potenciais-modal'>
          <div className='container-analise-potencial-vendas-container'>
            <button className='btn-close-modal-cards-potenciais' onClick={() => setIsVisible(false)}>X</button>

            <div className='container-title'>
              <FaChartLine className='title-icon' />
              <h3>Cards com Maior Potencial de Venda</h3>
            </div>

            <div className='list-cards-potenciais'>
              {topCards.slice(0, 3).map((card, index) => (
                <div key={card.card_id} className='card-potencial-venda'>
                  <div className='card-header-card-potencial'>
                    {getMedalIcon(index)}
                    <h3>{card.card_name}</h3>
                    <p className='score-text'><strong>Score:</strong> {Math.floor(card.score)}</p>
                  </div>
                  <p><strong>Responsável:</strong> {card.responsavel}</p>
                  <p><strong>Potencial de Venda:</strong> {card.potencial_venda}</p>
                  <p><strong>Processo Atual:</strong> {card.process_column_name}</p>
                  <p><strong>Origem:</strong> {card.origem}</p>
                  <p><strong>Produto:</strong> {card.produto}</p>
                  <p><strong>Valor:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(card.cost_value
                  )}</p>
                </div>
              ))}
            </div>

          </div>

        </div>

      }


      {showLeftMenu && (

        <div onClick={() => setShowLeftMenu(false)} className='modal-left-menu-container'>

          <div className='left-menu-container'>

            <div className='left-menu-title'>Menu</div>

            <button className='left-menu-button' onClick={() => pipelinePage()}>Home</button>
            {getAccessLevel('dashboard') &&
              <button className='left-menu-button' onClick={() => dashboardPage()}>Dashboard</button>
            }

            <button className='left-menu-button' onClick={() => PCP()}>PCP</button>

            <button className='left-menu-button' onClick={() => participantesPage()}>Participante</button>


            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => usersPage()}>Usuários</button>
            }



            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => processColumnsPage()}>Configurações</button>
            }

            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => setOpenCloseImportExcelEntidades(true)}>Import Excel</button>
            }

            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => setOpenCloseImportExcelSuiteFlow(true)}>Import SuiteFlow</button>
            }

            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => tvSliderPage()}>TV Slider</button>
            }

            {getAccessLevel('adm') &&
              <button className='left-menu-button' onClick={() => projetosPage()}>Projetos</button>
            }

          </div>
        </div>
      )}

      <div className='header-user-logo-container' onClick={() => openCloseInfosUser()}>


        <img
          onClick={() => openCloseInfosUser()}
          className='header-users-logo-header'
          src={user && user.avatar ? (userAvatar?.includes('syncs-avatar') ? require(`../../assets/avatares/${userAvatar}`) : user.avatar) : Logo}
          alt={`${user && user.username}'s avatar`}
        />


      </div>

      {showMenuUser && (
        <div className='user-infors-container'>

          <img className='img-menu-header-user' src={img01} alt="Descrição da imagem" />


          <button className='btn-close-metas-user' onClick={() => openCloseInfosUser()}>x</button>

          <div className='user-infors-logo-container' onClick={() => openCloseInfosUser()}>
            {/* <img onClick={() => openCloseInfosUser()} className='user-infors-logo' src={user && user.avatar ? user.avatar : Logo} /> */}

            <img
              onClick={() => setOpenCloseModalAvatar(true)}
              className='header-users-logo'
              src={user && user.avatar ? (userAvatar?.includes('syncs-avatar') ? require(`../../assets/avatares/${userAvatar}`) : user.avatar) : Logo}
              alt={`${user && user.username}'s avatar`}
            />

            <button style={{ display: 'none' }} className='btn-edit-avatar-user' onClick={() => setOpenCloseModalAvatar(true)}>editar</button>

          </div>

          <label className='user-info-name'>{user ? user.username : ''}</label>

          <div className='user-infors-body-container'>
            <label className='title-progress-bar'>{user ? user.username : ''}</label>
            <div className="progress-bar-container">
              <div className="progress-label">
                {`${percentualProgresso.toFixed(0)}%`}
              </div>
              <div className="progress-bar" style={{ width: `${percentualProgresso}%` }}></div>
            </div>
            <label className="value-meta-label">
              {'Vendas: '}
              {parseFloat(totalVendas).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              {' / Meta: '}
              {parseFloat(user ? user.meta_user : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </label>
            <label className='title-progress-bar'>Afilhados</label>
            <div className="progress-bar-container">
              <div className="progress-label">
                {`${percentualProgressoGrupo.toFixed(0)}%`}
              </div>
              <div className="progress-bar" style={{ width: `${percentualProgressoGrupo}%` }}></div>
            </div>
            <label className="value-meta-label">
              {'Vendas: '}
              {parseFloat(totalVendasGrupo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              {' / Meta: '}
              {parseFloat(user ? user.meta_grupo : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </label>
          </div>
          <div className='user-infors-footer-container'>
            <button className='btn-close' onClick={handleLogout}>Sair</button>
          </div>
        </div>
      )}

      {showNotifications && (
        <div className='menu-notification-container'>
          <div className='menu-notification-header'>
            <button className='btn-close-notifications-menu' onClick={() => setShowNotifications(!showNotifications)}>X</button>


            <MdOutlineCalendarMonth className='btn-icon-calendario' onClick={() => setShowCalendar(true)} />


            <div className='total-mensagens-text' > {numberNotifications} Mensagens</div>

          </div>
          <div className='menu-notification-body' ref={notificationsRef} style={{ overflowY: 'auto' }}>
            {renderedNotifications.map((item) => (
              <div key={item.task_id} className='item-notifications'>
                <label className='label-notifications-header'><MdAddTask className='icon-notifications-card' />{item.description}</label>
                <label className='label-notifications-header'><MdDateRange className='icon-notifications-card' />{formatDate(item.due_date)}</label>
                <PreviewCard key={item.card_id} cardData={item} />
              </div>
            ))}
          </div>
        </div>
      )}

      {openCloseUpdateCard && (
        <UpdateCard idCard={currentCardData.card_id} cardData={currentCardData} />
      )}

      {openCloseHistoricModal && (
        <Historic idCard={currentCardData.card_id} cardData={currentCardData} closeModal={() => setOpenCloseHistoricModal(true)} />
      )}

      {openCloseTarefasModal && (
        <Tarefas idCard={currentCardData.card_id} cardData={currentCardData} closeModal={() => setOpenCloseTarefasModal(true)} />
      )}

      {openCloseCompartilharModal && (
        <Compartilhar idCard={currentCardData.card_id} cardData={currentCardData} closeModal={() => setOpenCloseCompartilharModal(true)} />
      )}

      {openCloseModuloEsquadriasModal && (
        <ModuloEsquadrias idCard={currentCardData.card_id} />
      )}

      {openCloseAnexosModal && (
        <Anexos idCard={currentCardData.card_id} cardData={currentCardData} />
      )}

      {openCloseCustomModule && (
        <CustomModule idCard={currentCardData.card_id} />
      )}


      {openClosePedidosModal && (
        <ModuloPedidos idCard={currentCardData.card_id} />
      )}




      <Loading />

      <TbMessageDots
      onMouseEnter={(e) => showTooltip(e, 'Chat')} onMouseLeave={hideTooltip}
        onClick={() => setOpenCloseModalMessenger(true)}
        className={`icon-messenger-flutuante ${unreadMessagesCount > 0 ? 'pulsing' : ''}`}
      />

      {openCloseModalMessenger &&
        <Messenger  onMouseLeave={hideTooltip} closeModal={closeModal} />
      }

      {unreadMessagesCount > 0 && <span className="badge">{unreadMessagesCount}</span>}
      {openCloseModalMessenger && <Messenger  onMouseLeave={hideTooltip} closeModal={closeModal} />}


      {showCalendar && <Calendario userId={user.id} closeModal={() => setShowCalendar(false)} />}

      {showFaktoryModule && <Faktory cardData={currentCardData} />}


          {/* Tooltip com posição fixa */}
          {tooltip.visible && (
            <div
              style={{
                position: 'fixed', // Fixado à tela
                top: tooltip.y + 74,
                left: tooltip.x ,
                transform: 'translate(-50%, -100%)', // Centraliza horizontalmente e posiciona acima
                background: 'black',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                whiteSpace: 'nowrap',
                zIndex: 1000,
              }}
            >
              {tooltip.text}
            </div>
          )}



    </header>
  );
}

export default Header;
