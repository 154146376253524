import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from './userContext';
import { apiUrl } from '../config/apiConfig';
import axios from 'axios';

import api from "../../src/services/api"



const ColumnsContext = createContext();

export const useColumns = () => useContext(ColumnsContext);

export const ColumnsProvider = ({ children }) => {

  const { user, empresa } = useUser();
  
  const [columns, setColumns] = useState([]);
  const [openCloseCreateColumn, setOpenCloseCreateColumn] = useState(false);
  const [columnsUser, setColumnsUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingResult, setLoadingResult] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedAfilhados, setSelectedAfilhados] = useState([]);


  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const [orderBy, setOrderBy] = useState(null);
  const [isAscending, setIsAscending] = useState(true);


  
const [currentTipologia, setCurrentTipologia] = useState(null);


const [selectedEtiqueta, setSelectedEtiqueta] = useState();


const [listaCardsFiltradosLocalmente, setListaCardsFiltradosLocalmente] = useState([]);



// FILTRO DE BUSCA LOCAL
const [tipoBuscaLocal, setTipoBuscaLocal] = useState();

  
const [localSearchTerm, setLocalSearchTerm] = useState();

const [buscaCombinadaComColuna, setBuscaCombinadaComColuna] = useState(false);








const [selectedSetores, setSelectedSetores] = useState([]);



const [showFaktoryModule, setShowFaktoryModule] = useState(false); 

const [filteredColumnsBuscaLocal, setFilteredColumnsBuscaLocal] = useState([]);


const [nivelBuscaCards, setNivelBuscaCards] = useState(0);



  // useEffect(() => {
  //   const fetchColumns = async () => {
  //     if (user && user.empresa_id) {
  //       const empresaId = user.empresa_id;

  //       try {
  //         const config = {
  //           headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  //         };
  //         const response = await axios.get(`${apiUrl}/process-columns/by-company/${empresaId}`, config);

  //         if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
  //           setColumns([response.data]);
  //         } else {
  //           setColumns(response.data);
  //         }
  //       } catch (error) {
  //         console.error('Erro ao buscar colunas:', error);
  //         setColumns([]);
  //       }
  //     }
  //   };

  //   fetchColumns();
  // }, [user]);

  useEffect(() => {
    const fetchColumns = async () => {
      if (user && user.empresa_id) {
        const empresaId = user.empresa_id;
  
        try {
          const response = await api.get(`/process-columns/by-company/${empresaId}`);
  
          if (response.data && typeof response.data === 'object' && !Array.isArray(response.data)) {
            setColumns([response.data]);
          } else {
            setColumns(response.data);
          }
        } catch (error) {
          console.error('Erro ao buscar colunas:', error);
          setColumns([]);
        }
      }
    };
  
    fetchColumns();
  }, [user]);
  

  // useEffect(() => {
  //   const fetchUserColumnsInfo = async () => {
  //     if (user && user.id) {
  //       setIsLoading(true);

  //       try {
  //         const response = await axios.get(`${apiUrl}/users/${user.id}/columns-info`, {
  //           headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
  //         });

  //         setColumnsUser(response.data);
  //       } catch (error) {
  //         console.error('Erro ao buscar informações das colunas do usuário:', error);
  //       }

  //       setIsLoading(false);
  //     }
  //   };

  //   fetchUserColumnsInfo();
  // }, [user]);

  useEffect(() => {
    const fetchUserColumnsInfo = async () => {
      if (user && user.id) {
        setIsLoading(true);
  
        try {
          const response = await api.get(`/users/${user.id}/columns-info`);
          setColumnsUser(response.data);
        } catch (error) {
          console.error('Erro ao buscar informações das colunas do usuário:', error);
        }
  
        setIsLoading(false);
      }
    };
  
    fetchUserColumnsInfo();
  }, [user]);
  


  const handleLocalSearch = (searchTerm) => {
    setLocalSearchTerm(searchTerm.toLowerCase());
  };


// Obtém a lista de setores únicos das colunas do usuário
const setores = Array.from(new Set(columnsUser.map(column => column.setor))).filter(Boolean);

// Seleciona todos os setores por padrão quando as colunas do usuário são carregadas
useEffect(() => {
  setSelectedSetores(setores);
}, [columnsUser]);

const handleSetorChange = (setor) => {
  setSelectedSetores(prevSetores =>
    prevSetores.includes(setor)
      ? prevSetores.filter(s => s !== setor)
      : [...prevSetores, setor]
  );
};




useEffect(() => {
  if (user && empresa && empresa.id) {
    toggleEditableColumnsContainer();
  }
}, [user, empresa]);

const [editableColumns, setEditableColumns] = useState([]);





// const toggleEditableColumnsContainer = async () => {
//   try {
//     const empresaId = empresa.id; // Ou obtenha de onde for apropriado

//     // Inclua o `empresaId` como um parâmetro de consulta
//     const response = await axios.get(`${apiUrl}/users/${user.id}/permissions?empresaId=${empresaId}`, {
//       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
//     });

//     //console.log(response.data)

//     setEditableColumns(response.data); // Atualize o estado com as permissões recebidas
//   } catch (error) {
//     //console.error('Erro ao buscar permissões de edição:', error);
//   }
// }
const toggleEditableColumnsContainer = async () => {
  try {
    const empresaId = empresa.id; // Obtém o ID da empresa

    // Faz a requisição utilizando `api`, sem necessidade de definir manualmente os headers
    const response = await api.get(`/users/${user.id}/permissions`, {
      params: { empresaId } // Passa `empresaId` como um parâmetro de consulta
    });

    setEditableColumns(response.data); // Atualiza o estado com as permissões recebidas
  } catch (error) {
    console.error('Erro ao buscar permissões de edição:', error);
  }
};



const [cardsFiltradosLocalmente, setCardsFiltradosLocalmente] = useState([]);


const [showSharedCards, setShowSharedCards] = useState(true); // Estado para controlar exibição de cartões compartilhados



const [listaEtiquetas, setListaEtiquetas] = useState([]);


// const buscarEtiquetas = async () => {
//   try {
//     const response = await axios.get(`${apiUrl}/card/etiquetas/${user.empresa_id}`);
//     setListaEtiquetas(response.data);
//   } catch (error) {
//     console.error('Erro ao buscar etiquetas:', error);
//   }
// };

const buscarEtiquetas = async () => {
  try {
    const response = await api.get(`/card/etiquetas/${user.empresa_id}`);
    setListaEtiquetas(response.data);
  } catch (error) {
    console.error('Erro ao buscar etiquetas:', error);
  }
};



useEffect(() => {
  if (!user)
    return
  buscarEtiquetas()
}, [user])






useEffect(() => {
  //console.log(filteredColumnsBuscaLocal)

}, [filteredColumnsBuscaLocal])





  const contextValue = {
    columns,
    setColumns,
    openCloseCreateColumn,
    openModalCreateColumn: () => setOpenCloseCreateColumn(!openCloseCreateColumn),
    columnsUser,
    isLoading,
    setColumnsUser,
    loadingResult,
    setLoadingResult,
    loadingModal,
    setLoadingModal,
    selectedAfilhados,
    setSelectedAfilhados,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    orderBy, setOrderBy,
    isAscending, setIsAscending,
    localSearchTerm,
    handleLocalSearch,
    setores,
    selectedSetores,
    setSelectedSetores,
    handleSetorChange,
    editableColumns, setEditableColumns,
    cardsFiltradosLocalmente, setCardsFiltradosLocalmente,
    showSharedCards, setShowSharedCards,
    listaEtiquetas, setListaEtiquetas,
    selectedEtiqueta, setSelectedEtiqueta,
    currentTipologia, setCurrentTipologia,
    listaCardsFiltradosLocalmente, setListaCardsFiltradosLocalmente,
    tipoBuscaLocal, setTipoBuscaLocal,
    showFaktoryModule, setShowFaktoryModule,
    filteredColumnsBuscaLocal, setFilteredColumnsBuscaLocal,
    nivelBuscaCards, setNivelBuscaCards,
    buscaCombinadaComColuna, setBuscaCombinadaComColuna

    
  };


  return (
    <ColumnsContext.Provider value={contextValue}>
      {children}
    </ColumnsContext.Provider>
  );
};
