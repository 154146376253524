import axios from "axios";

const api = axios.create({
  //baseURL: "http://localhost:3001",
  baseURL: "https://bbllrr.hospedagemelastica.com.br",

  
});

// ✅ Intercepta todas as requisições para incluir o token atualizado
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // 🔄 Sempre pega o token mais recente

  // console.log("📢 Token Enviado na Requisição:", token);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    console.warn("⚠️ Nenhum token encontrado! Requisição pode falhar.");
  }

  return config;
});

// ✅ Intercepta respostas para capturar erro 401 (Token expirado)
export const setupInterceptors = (logout) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        console.warn("⚠️ Erro 401: Token expirado. Deslogando usuário...");
        logout();
      }
      return Promise.reject(error);
    }
  );
};

export default api;


