import React, { useState, useEffect, useRef } from 'react';

// API
import axios from 'axios';
import { apiUrl } from '../../../config/apiConfig';
import api from '../../../services/api';

import { MdCheckCircle, MdOutlineSend, Md360, MdWindow, MdBuild, MdLocalShipping, MdThumbDown, MdRestoreFromTrash, MdThumbUp, MdGrade, MdWhatsapp, MdOutlineEmail, MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { format, parseISO } from 'date-fns';

// STYLE
import './style.css';

// CONTEXT API
import { useUser } from '../../../contexts/userContext';
import { useColumns } from '../../../contexts/columnsContext';
import { useCard } from '../../../contexts/cardContext'
import Logo from '../../../assets/logo-suite-flow.ico'

function Tarefas() {

  // CONTEXT API
  const { user, listAllUsers } = useUser();
  const { columnsUser } = useColumns();

  const { setOpenCloseUpdateCard, setCurrentCardData,
    setCards, setPreviewSearchCards,
    setListNotifications,
    setOpenCloseHistoricModal,
    setOpenCloseTarefasModal,
    setOpenCloseCompartilharModal,
    currentCardData,
    tarefas, setTarefas
  } = useCard();

  // ESTADOS LOCAL
  const [currentTarefa, setCurrentTarefa] = useState('');

  const [dueDate, setDueDate] = useState(''); // Estado para armazenar a data de vencimento


  const [taskType, setTaskType] = useState('Tarefa'); // 'Tarefa' ou 'Evento'
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');



  useEffect(() => {
    const fetchTarefas = async () => {

      try {
        // const response = await axios.get(`${apiUrl}/card/tarefas/${user.id}/${currentCardData.card_id}`);
        const response = await api.get(`/card/tarefas/${user.id}/${currentCardData.card_id}`);

        setTarefas(response.data);

      } catch (error) {
        console.error('Error fetching tarefas:', error);
      }
    };

    fetchTarefas();
  }, [currentCardData.card_id, user.id]);

  // Historic Component in React

  // const handleTarefas = async (event) => {
  //   event.preventDefault();

  //   // Verifica se a descrição ou a data de vencimento estão vazias
  //   if (!currentTarefa.trim() || !dueDate) {
  //     console.error('A descrição e a data de conclusão são obrigatórias.');
  //     return; // Interrompe a função se a descrição ou data não forem fornecidas
  //   }

  //   try {

  //     const dueDateWithTimezone = new Date(dueDate).toISOString();


  //     const payload = {
  //       user_id: user.id, // from useUser context
  //       card_id: currentCardData.card_id, // or any other type depending on the context
  //       description: currentTarefa,
  //       task_type: 'Card',
  //       due_date: dueDateWithTimezone,
  //       completed: false,
  //       empresa_id: user.empresa_id
  //     };

  //     const response = await axios.post(`${apiUrl}/card/add-tarefa`, payload);
  //     setTarefas([...tarefas, response.data]);
  //     setCurrentTarefa('');

  //   } catch (error) {
  //     console.error('Error adding card tarefa:', error);
  //   }
  // };

  const handleTarefas = async (event) => {
    event.preventDefault();

    // Validação: A descrição é obrigatória
    if (!currentTarefa.trim()) {
        alert('A descrição da tarefa/evento é obrigatória.');
        return;
    }

    // Validação: A data de conclusão é obrigatória
    if (!dueDate) {
        alert('A data de conclusão é obrigatória.');
        return;
    }

    // Validação: O horário de início é obrigatório para todas as tarefas
    if (!startTime) {
        alert('O horário de início é obrigatório para tarefas e eventos.');
        return;
    }

    // Validação: Para eventos, o horário de fim é obrigatório e deve ser maior que o início
    if (taskType === "Evento") {
        if (!endTime) {
            alert('O horário de fim é obrigatório para eventos.');
            return;
        }

        // Converter os horários para comparação (Formato HH:mm)
        const start = startTime.split(':').map(Number);
        const end = endTime.split(':').map(Number);

        const startMinutes = start[0] * 60 + start[1]; // Converter para minutos
        const endMinutes = end[0] * 60 + end[1]; // Converter para minutos

        if (endMinutes <= startMinutes) {
            alert('O horário de fim deve ser maior que o horário de início.');
            return;
        }
    }

    try {
        const dueDateWithTimezone = new Date(dueDate).toISOString();

        const payload = {
            user_id: user.id,
            card_id: currentCardData.card_id,
            task_type: taskType, // 'Tarefa' ou 'Evento'
            description: currentTarefa,
            due_date: dueDateWithTimezone,
            start_time: startTime, // Sempre enviado
            end_time: taskType === "Evento" ? endTime : null, // Para eventos, sempre enviado
            completed: false,
            empresa_id: user.empresa_id
        };

        // const response = await axios.post(`${apiUrl}/card/add-tarefa`, payload);
        const response = await api.post(`/card/add-tarefa`, payload);

        setTarefas([...tarefas, response.data]);
        setCurrentTarefa('');
        setStartTime('');
        setEndTime('');

    } catch (error) {
        alert('Erro ao adicionar tarefa/evento. Tente novamente mais tarde.');
        console.error('Erro ao adicionar tarefa/evento:', error);
    }
};





  const updateTarefas = async (event, task_id, newStatus, taskOwnerId) => {
    event.preventDefault();
    event.stopPropagation();

    if (taskOwnerId !== user.id) {
      alert('Apenas o criador da tarefa pode finalizá-la.');
      return;
    }


    try {
      const payload = {
        task_id: task_id,
        completed: newStatus,
        card_id: currentCardData.card_id,
      };

      // const response = await axios.post(`${apiUrl}/card/update-tarefa`, payload);
      const response = await api.post(`/card/update-tarefa`, payload);


      if (response.data) {
        setTarefas(prevTarefas =>
          prevTarefas.map(tarefa =>
            tarefa.task_id === task_id ? { ...tarefa, ...response.data } : tarefa
          )
        );
        setCurrentTarefa('');
      }

    } catch (error) {
      console.error('Error updating card tarefa:', error);
    }
  };


  useEffect(() => {
  }, [tarefas]);


  function formatDate(dateString) {
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yyyy - HH:mm:ss');
  }

  function formatDateSimple(dateString) {
    const date = new Date(dateString);
    const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return format(adjustedDate, 'dd/MM/yyyy');
  }




  const containerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      // Ajusta a posição de rolagem para o final do contêiner
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, [tarefas]);

  const getUserData = (userId) => {
    return listAllUsers.find(u => u.id === userId);
  };


  return (
    <div className='tarefa-card-modal'>
      <div className='tarefa-card-container'>
        <div className='tarefa-card-footer'>
          <div className='header-update-card-container'>
            <label>Tarefas</label>
          </div>
          <button className="tarefa-card-close-button" onClick={() => setOpenCloseTarefasModal(false)}>X</button>
        </div>
        <div className="tarefa-card-form-container">

          <div ref={containerRef} className='tarefa-card-list-container'>




            {tarefas.map((item) => (
              <div className='tarefa-item-container' key={item.task_id}>
                <div className='tarefa-mensagem-container'>
                  <div className='user-logo-tarefa-container'>
                    {/* <img className='user-logo-history' src={getUserData(item.user_id)?.avatar || Logo} /> */}

                    <img
                      className='user-logo-task-img'
                      src={user && user.avatar ? (getUserData(item.user_id)?.avatar?.includes('syncs-avatar') ? require(`../../../assets/avatares/${getUserData(item.user_id).avatar}`) : getUserData(item.user_id).avatar) : Logo}
                    />

                  </div>
                  <p className='tarefa-description'>
                    <label className='tarefa-description-title'>{item.description}</label>
                    <div className='date-time-container-tasks'>
                      <span className='tarefa-description-date'>{item.task_type}: </span>
                      <label style={{marginLeft: '8px'}} className='tarefa-description-date'>{formatDateSimple(item.due_date)}</label>

                      <span style={{marginLeft: '8px'}} className='tarefa-description-date'>{item.start_time ? item.start_time : '00:00'} {item.task_type === "Evento" ? ` - ${item.end_time || ''}` : ''}</span>
                    </div>

                  </p>


                  {/* <MdCheckCircle style={{ color: item.completed === true ? 'dodgerblue' : '' }} className='btn-completed-task' onClick={(e) => updateTarefas(e, item.task_id, !item.completed)} /> */}

                  <MdCheckCircle
                    style={{ color: item.completed === true ? 'dodgerblue' : '' }}
                    className='btn-completed-task'
                    onClick={(e) => updateTarefas(e, item.task_id, !item.completed, item.user_id)}
                  />


                </div>
                <p className='tarefa-date'>
                  {getUserData(item.user_id)?.username || ''} - {formatDate(item.created_at)}   /   {item.complete_date ? formatDate(item.complete_date) : 'Aguardando'}
                </p>
              </div>
            ))}

          </div>

          <form className="tarefa-card-form">
            <select
              value={taskType}
              onChange={(e) => setTaskType(e.target.value)}
              className="tarefa-card-input"
            >
              <option value="Tarefa">Tarefa</option>
              <option value="Evento">Evento</option>
            </select>

            <input
              id="username"
              className="tarefa-card-input"
              type="text"
              placeholder="Descrição da Tarefa/Evento"
              value={currentTarefa}
              onChange={(e) => setCurrentTarefa(e.target.value)}
            />

            <input
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              className="tarefa-card-input"
            />

            {taskType === "Tarefa" && (
              <div className='event-horario-container'>
                <label className='label-event-horario'>Início:</label>
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="tarefa-card-input"
                  placeholder="Horário"
                  style={{ width: '300px' }}
                />

              </div>

            )}

            {taskType === "Evento" && (
              <div className='event-horario-container'>
                <label className='label-event-horario'>Início:</label>
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="tarefa-card-input"
                  placeholder="Horário de Início"
                />
                <label className='label-event-horario'>Fim:</label>

                <input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="tarefa-card-input"
                  placeholder="Horário de Fim"
                />
              </div>
            )}
          </form>

          <div className='tarefa-card-footer'>
            <button  type="submit" className="tarefa-card-button" onClick={(e) => {
              e.stopPropagation();
              handleTarefas(e);
            }} >
              Adicionar Tarefa
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tarefas; 
