import React, { useState, useEffect } from 'react';
import api from '../../services/api';

// API
import axios from 'axios';
import { apiUrl } from '../../config/apiConfig';
import './style.css';

import { useUser } from '../../contexts/userContext'
import { useColumns } from '../../contexts/columnsContext';
import { useCard } from '../../contexts/cardContext'

import { IoStarSharp } from "react-icons/io5";



const PedidoPedido = () => {

  const { user, listAllUsers, getAccessLevel, empresa } = useUser();
  const { columns, editableColumns } = useColumns();
  const { setCards, setOpenClosePedidosModal, currentCardData, addHistoricoCardContext } = useCard();

  const [numeroPedido, setNumeroPedido] = useState('');
  const [statusPedido, setStatusPedido] = useState('Parado');
  const [dataStatus, setDataStatus] = useState(null);
  const [nomeObra, setNomeObra] = useState('');
  const [representante, setRepresentante] = useState(0);
  const [nomeCliente, setNomeCliente] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [telefoneCliente, setTelefoneCliente] = useState('');
  const [nomeContato, setNomeContato] = useState('');
  const [emailNotaFiscal, setEmailNotaFiscal] = useState('');
  const [representanteLegalNome, setRepresentanteLegalNome] = useState('');
  const [representanteLegalEmail, setRepresentanteLegalEmail] = useState('');
  const [representanteLegalCpf, setRepresentanteLegalCpf] = useState('');
  const [enderecoCobrancaResponsavel, setEnderecoCobrancaResponsavel] = useState('');
  const [enderecoCobrancaTelefone, setEnderecoCobrancaTelefone] = useState('');
  const [enderecoCobrancaEmail, setEnderecoCobrancaEmail] = useState('');
  const [enderecoCobrancaLogradouro, setEnderecoCobrancaLogradouro] = useState('');
  const [enderecoCobrancaNumero, setEnderecoCobrancaNumero] = useState('');
  const [enderecoCobrancaComplemento, setEnderecoCobrancaComplemento] = useState('');
  const [enderecoCobrancaBairro, setEnderecoCobrancaBairro] = useState('');
  const [enderecoCobrancaCidade, setEnderecoCobrancaCidade] = useState('');
  const [enderecoCobrancaUf, setEnderecoCobrancaUf] = useState('');
  const [enderecoCobrancaCep, setEnderecoCobrancaCep] = useState('');
  const [enderecoCobrancaCondominio, setEnderecoCobrancaCondominio] = useState('');
  const [enderecoEntregaLogradouro, setEnderecoEntregaLogradouro] = useState('');
  const [enderecoEntregaNumero, setEnderecoEntregaNumero] = useState('');
  const [enderecoEntregaComplemento, setEnderecoEntregaComplemento] = useState('');
  const [enderecoEntregaBairro, setEnderecoEntregaBairro] = useState('');
  const [enderecoEntregaCidade, setEnderecoEntregaCidade] = useState('');
  const [enderecoEntregaUf, setEnderecoEntregaUf] = useState('');
  const [enderecoEntregaCep, setEnderecoEntregaCep] = useState('');
  const [enderecoEntregaCondominio, setEnderecoEntregaCondominio] = useState('');
  const [gestorObraNome, setGestorObraNome] = useState('');
  const [gestorObraTelefone, setGestorObraTelefone] = useState('');
  const [gestorObraEmail, setGestorObraEmail] = useState('');
  const [condicoesPagamento, setCondicoesPagamento] = useState('');
  const [condicoesPagamentoTerceiro, setCondicoesPagamentoTerceiro] = useState('');
  const [previsaoMedicao, setPrevisaoMedicao] = useState(null);
  const [previsaoEntrega, setPrevisaoEntrega] = useState(null);
  const [observacoes, setObservacoes] = useState('');

  const [valorInstalacao, setValorInstalacao] = useState(0);
  const [valorFrete, setValorFrete] = useState(0);
  const [valorAbatimentoShowroom, setValorAbatimentoShowroom] = useState(0);

  const [valorFreteEsquadrias, setValorFreteEsquadrias] = useState(0);
  const [valorProjeto, setValorProjeto] = useState(0);
  const [valorVidrosSeparados, setValorVidrosSeparados] = useState(0);

  const [valorExternasEsquadrias, setValorExternasEsquadrias] = useState(0);
  const [descontoExternas, setDescontoExternas] = useState(0);
  const [valorFinalExternas, setValorFinalExternas] = useState(0);

  const [valorOutrosEsquadrias, setValorOutrosEsquadrias] = useState(0);
  const [descontoOutros, setDescontoOutros] = useState(0);
  const [valorFinalOutros, setValorFinalOutros] = useState(0);

  const [valorInstalacaoPVC, setValorInstalacaoPVC] = useState(0);

  const [estados, setEstados] = useState([]);
  const [cidadesCobranca, setCidadesCobranca] = useState([]);
  const [cidadesEntrega, setCidadesEntrega] = useState([]);


  const [emailAssinaturaContrato, setEmailAssinaturaContrato] = useState('');
  const [comissao, setComissao] = useState(0);

  const [errors, setErrors] = useState({});

  const [versions, setVersions] = useState([]);

  const [pedidoData, setPedidoData] = useState({});

  const [enderecoCobrancaEmail2, setEnderecoCobrancaEmail2] = useState('');
  const [emailNotaFiscal2, setEmailNotaFiscal2] = useState('');


  const [observacoesOutros, setObservacoesOutros] = useState('');



  // Pegue o id de todas as colunas editáveis
  const editableColumnIds = editableColumns.map(column => column.columnId);

  // Função para verificar se o usuário pode editar com base no column_id
  const canEditField = () => {
    return editableColumnIds.includes(currentCardData.column_id);
  };
  // Função para impedir edição e exibir mensagem de alerta
  const handleFieldClick = (e) => {
    if (!canEditField()) {
      e.preventDefault(); // Impede a edição
      alert('Você não tem permissão para editar este pedido na coluna atual! Entre em contato com o Admin.');
    }
  };








  const validateFields = () => {
    const newErrors = {};





    // Validação de campos numéricos
    if (String(valorExternasEsquadrias).includes(',')) {
      newErrors.valorExternasEsquadrias = 'Utilize  PONTO no lugar de vírgula em "Externas". ( Ex: 1000.56 )    ';
    }

    if (String(descontoExternas).includes(',')) {
      newErrors.descontoExternas = 'Utilize  PONTO no lugar de vírgula em "Desconto". ( Ex: 1000.56 )    ';
    }

    if (String(valorOutrosEsquadrias).includes(',')) {
      newErrors.valorOutrosEsquadrias = 'Utilize  PONTO no lugar de vírgula em "Outros". ( Ex: 1000.56 )    ';
    }

    if (String(descontoOutros).includes(',')) {
      newErrors.descontoOutros = 'Utilize  PONTO no lugar de vírgula em "Desconto Outros". ( Ex: 1000.56 )    ';
    }

    if (String(valorInstalacao).includes(',')) {
      newErrors.valorInstalacao = 'Utilize  PONTO no lugar de vírgula em "Instalação". ( Ex: 1000.56 )    ';
    }

    if (String(valorFrete).includes(',')) {
      newErrors.valorFrete = 'Utilize  PONTO no lugar de vírgula em "Frete". ( Ex: 1000.56 )    ';
    }

    if (String(valorAbatimentoShowroom).includes(',')) {
      newErrors.valorAbatimentoShowroom = 'Utilize  PONTO no lugar de vírgula em "Arredondamento". ( Ex: 1000.56 )    ';
    }

    if (String(valorInstalacaoPVC).includes(',')) {
      newErrors.valorInstalacaoPVC = 'Utilize  PONTO no lugar de vírgula em "Instalação PVC". ( Ex: 1000.56 )    ';
    }

    if (String(valorFreteEsquadrias).includes(',')) {
      newErrors.valorFreteEsquadrias = 'Utilize  PONTO no lugar de vírgula em "Frete Esquadrias". ( Ex: 1000.56 )    ';
    }

    if (String(valorProjeto).includes(',')) {
      newErrors.valorProjeto = 'Utilize  PONTO no lugar de vírgula em "Projeto". ( Ex: 1000.56 )    ';
    }

    if (String(valorVidrosSeparados).includes(',')) {
      newErrors.valorVidrosSeparados = 'Utilize  PONTO no lugar de vírgula em "Vidros Separados". ( Ex: 1000.56 )     ';
    }





    if (parseFloat(valorOutrosEsquadrias) > 0 && !observacoesOutros) {
      newErrors.observacoesOutros = 'Observação para "Outros" é obrigatória quando o valor é maior que 0';
    }





    const isValidCPF = (cpf) => {
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

      let sum = 0, remainder;
      for (let i = 1; i <= 9; i++) sum += parseInt(cpf[i - 1]) * (11 - i);
      remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) remainder = 0;
      if (remainder !== parseInt(cpf[9])) return false;

      sum = 0;
      for (let i = 1; i <= 10; i++) sum += parseInt(cpf[i - 1]) * (12 - i);
      remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) remainder = 0;
      return remainder === parseInt(cpf[10]);
    };

    const isValidCNPJ = (cnpj) => {
      cnpj = cnpj.replace(/[^\d]+/g, '');
      if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;

      let size = cnpj.length - 2;
      let numbers = cnpj.substring(0, size);
      let digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;

      for (let i = size; i >= 1; i--) {
        sum += numbers[size - i] * pos--;
        if (pos < 2) pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result !== parseInt(digits[0])) return false;

      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers[size - i] * pos--;
        if (pos < 2) pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      return result === parseInt(digits[1]);
    };


    if (!isValidCPF(cpfCnpj) && !isValidCNPJ(cpfCnpj)) {
      newErrors.cpfCnpj = 'CPF ou CNPJ inválido';
    }




    // Validação de CNPJ
    if (/^\d{14}$/.test(cpfCnpj)) {
      if (!representanteLegalNome) newErrors.representanteLegalNome = 'Nome do Representante Legal é obrigatório';
      if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(representanteLegalEmail)) newErrors.representanteLegalEmail = 'Insira um único e-mail válido.';
      if (!/^\d{11}$/.test(representanteLegalCpf)) newErrors.representanteLegalCpf = 'CPF do Representante Legal inválido';
    }

    // Outras validações
    if (!numeroPedido) newErrors.numeroPedido = 'Número do Pedido é obrigatório';
    if (!statusPedido) newErrors.statusPedido = 'Status do Pedido é obrigatório';
    if (!nomeObra) newErrors.nomeObra = 'Nome da Obra é obrigatório';
    if (!representante) newErrors.representante = 'Representante é obrigatório';
    if (!nomeCliente) newErrors.nomeCliente = 'Nome do Cliente é obrigatório';
    if (!/^\d{11}|\d{14}$/.test(cpfCnpj)) newErrors.cpfCnpj = 'CPF/CNPJ inválido';
    if (!/^\d{10,11}$/.test(telefoneCliente)) newErrors.telefoneCliente = 'Telefone inválido';
    if (!nomeContato) newErrors.nomeContato = 'Nome para Contato é obrigatório';

    if (!condicoesPagamento) newErrors.condicoesPagamento = 'Condição de pagamento é obrigatório!';

    // Validação única para emails
    if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(emailNotaFiscal)) {
      newErrors.emailNotaFiscal = 'Insira um único e-mail válido.';
    }

    if (!enderecoCobrancaResponsavel) newErrors.enderecoCobrancaResponsavel = 'Responsável pelo Endereço de Cobrança é obrigatório';
    if (!/^\d{10,11}$/.test(enderecoCobrancaTelefone)) newErrors.enderecoCobrancaTelefone = 'Telefone do Endereço de Cobrança é inválido';

    if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(enderecoCobrancaEmail)) {
      newErrors.enderecoCobrancaEmail = 'Insira um único e-mail válido.';
    }

    if (!enderecoCobrancaLogradouro) newErrors.enderecoCobrancaLogradouro = 'Logradouro do Endereço de Cobrança é obrigatório';
    if (!enderecoCobrancaNumero) newErrors.enderecoCobrancaNumero = 'Número do Endereço de Cobrança é obrigatório';
    if (!enderecoCobrancaBairro) newErrors.enderecoCobrancaBairro = 'Bairro do Endereço de Cobrança é obrigatório';
    if (!enderecoCobrancaCidade) newErrors.enderecoCobrancaCidade = 'Cidade do Endereço de Cobrança é obrigatória';
    if (!enderecoCobrancaUf) newErrors.enderecoCobrancaUf = 'Estado do Endereço de Cobrança é obrigatório';
    if (!/^\d{8}$/.test(enderecoCobrancaCep)) newErrors.enderecoCobrancaCep = 'CEP do Endereço de Cobrança é inválido';

    if (!enderecoEntregaLogradouro) newErrors.enderecoEntregaLogradouro = 'Logradouro do Endereço de Entrega é obrigatório';
    if (!enderecoEntregaNumero) newErrors.enderecoEntregaNumero = 'Número do Endereço de Entrega é obrigatório';
    if (!enderecoEntregaBairro) newErrors.enderecoEntregaBairro = 'Bairro do Endereço de Entrega é obrigatório';
    if (!enderecoEntregaCidade) newErrors.enderecoEntregaCidade = 'Cidade do Endereço de Entrega é obrigatória';
    if (!enderecoEntregaUf) newErrors.enderecoEntregaUf = 'Estado do Endereço de Entrega é obrigatório';
    if (!/^\d{8}$/.test(enderecoEntregaCep)) newErrors.enderecoEntregaCep = 'CEP do Endereço de Entrega é inválido';



    if (!previsaoMedicao) newErrors.previsaoMedicao = 'Previsão de Medição é obrigatória';
    if (!previsaoEntrega) newErrors.previsaoEntrega = 'Previsão de Entrega é obrigatória';

    if (!/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(emailAssinaturaContrato)) {
      newErrors.emailAssinaturaContrato = 'Insira um único e-mail válido.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  useEffect(() => {
    axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => {
        setEstados(response.data.sort((a, b) => a.nome.localeCompare(b.nome)));
      });
  }, []);




  const [currentVersion, setCurrentVersion] = useState(1);



  // useEffect(() => {

  //   console.log(currentCardData)
  //   const fetchAllVersions = async () => {
  //     try {
  //       const response = await axios.get(`${apiUrl}/users/pedido/${currentCardData.card_id}/versions`);
  //       if (response.data) {
  //         setVersions(response.data);

  //         const activeVersion = response.data.find(version => version.versao_ativa);
  //         const version = response.data.find(version => version.versao == 1);


  //         if (activeVersion) {
  //           setCurrentVersion(activeVersion.versao);
  //           setPedidoData(activeVersion);
  //           handleVersionSelect(activeVersion.versao, activeVersion)
  //         } else {

  //           previewInfosCard()

  //         }

  //         const pedido = response.data[0]; // Seleciona a primeira versão como exemplo

  //         if (pedido.endereco_cobranca_uf) {
  //           await loadCidades('cobranca', pedido.endereco_cobranca_uf);
  //         }
  //         if (pedido.endereco_entrega_uf) {
  //           await loadCidades('entrega', pedido.endereco_entrega_uf);
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Erro ao buscar as versões dos pedidos:', error);
  //     }
  //   };

  //   fetchAllVersions();
  // }, [currentCardData]);

useEffect(() => {
  console.log(currentCardData);

  const fetchAllVersions = async () => {
    try {
      const response = await api.get(`/users/pedido/${currentCardData.card_id}/versions`);
      if (response.data) {
        setVersions(response.data);

        const activeVersion = response.data.find(version => version.versao_ativa);
        const version = response.data.find(version => version.versao == 1);

        if (activeVersion) {
          setCurrentVersion(activeVersion.versao);
          setPedidoData(activeVersion);
          handleVersionSelect(activeVersion.versao, activeVersion);
        } else {
          previewInfosCard();
        }

        const pedido = response.data[0]; // Seleciona a primeira versão como exemplo

        if (pedido?.endereco_cobranca_uf) {
          await loadCidades('cobranca', pedido.endereco_cobranca_uf);
        }
        if (pedido?.endereco_entrega_uf) {
          await loadCidades('entrega', pedido.endereco_entrega_uf);
        }
      }
    } catch (error) {
      console.error("Erro ao buscar as versões dos pedidos:", error);
    }
  };

  fetchAllVersions();
}, [currentCardData]);



  const previewInfosCard = () => {

    setNumeroPedido(currentCardData.document_number || '');
    setNomeObra(currentCardData.name || '');
    setNomeCliente(currentCardData.name || '');
    setCpfCnpj(currentCardData.cpf || '');
    setTelefoneCliente(currentCardData.fone || '');
    setNomeContato(currentCardData.responsavel || '');
    setEmailNotaFiscal(currentCardData.email || '');
    setEnderecoCobrancaResponsavel(currentCardData.name || '');
    setEnderecoCobrancaTelefone(currentCardData.fone || '');
    setEnderecoCobrancaEmail(currentCardData.email || '');
    setEnderecoCobrancaLogradouro(currentCardData.endereco || '');
    setEnderecoCobrancaCidade(currentCardData.city || '');
    setEnderecoCobrancaUf(currentCardData.state || '');
    setEnderecoEntregaLogradouro(currentCardData.endereco || '');
    setEnderecoEntregaCidade(currentCardData.city || '');
    setEnderecoEntregaUf(currentCardData.state || '');
    setGestorObraNome(currentCardData.name || '');
    setGestorObraTelefone(currentCardData.fone || '');
    setGestorObraEmail(currentCardData.email || '');
    setComissao(0);
    setEmailAssinaturaContrato(currentCardData.email || '');
    setValorExternasEsquadrias(currentCardData.cost_value || 0);

  };


  const loadCidades = async (tipo, uf) => {
    try {
      const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
      const sortedCidades = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
      if (tipo === 'cobranca') {
        setCidadesCobranca(sortedCidades);
      } else {
        setCidadesEntrega(sortedCidades);
      }
    } catch (error) {
      console.error('Erro ao carregar as cidades:', error);
    }
  };


  useEffect(() => {

    setRepresentante(currentCardData.entity_id);
  }, [currentCardData]);


  const handleVersionSelect = (versionId, dataPedido) => {
    const selectedVersion = dataPedido; //versions.find(version => version.versao === versionId);


    setCurrentVersion(versionId)

    if (selectedVersion) {
      setPedidoData(selectedVersion); // Carrega os dados da versão selecionada
      setNumeroPedido(selectedVersion.numero_pedido || '');
      setStatusPedido(selectedVersion.status_pedido || 'Parado');
      setDataStatus(formatDate(selectedVersion.data_status) || '');
      setNomeObra(selectedVersion.nome_obra || currentCardData.name);
      setRepresentante(selectedVersion.representante || currentCardData.entity_id || '');
      setNomeCliente(selectedVersion.nome_cliente || '');
      setCpfCnpj(selectedVersion.cpf_cnpj || '');
      setTelefoneCliente(selectedVersion.telefone_cliente || '');
      setNomeContato(selectedVersion.nome_contato || '');
      setEmailNotaFiscal(selectedVersion.email_nota_fiscal || '');
      setRepresentanteLegalNome(selectedVersion.representante_legal_nome || '');
      setRepresentanteLegalEmail(selectedVersion.representante_legal_email || '');
      setRepresentanteLegalCpf(selectedVersion.representante_legal_cpf || '');
      setEnderecoCobrancaResponsavel(selectedVersion.endereco_cobranca_responsavel || '');
      setEnderecoCobrancaTelefone(selectedVersion.endereco_cobranca_telefone || '');
      setEnderecoCobrancaEmail(selectedVersion.endereco_cobranca_email || '');
      setEnderecoCobrancaLogradouro(selectedVersion.endereco_cobranca_logradouro || '');
      setEnderecoCobrancaNumero(selectedVersion.endereco_cobranca_numero || '');
      setEnderecoCobrancaComplemento(selectedVersion.endereco_cobranca_complemento || '');
      setEnderecoCobrancaBairro(selectedVersion.endereco_cobranca_bairro || '');
      setEnderecoCobrancaCidade(selectedVersion.endereco_cobranca_cidade || '');
      setEnderecoCobrancaUf(selectedVersion.endereco_cobranca_uf || '');
      setEnderecoCobrancaCep(selectedVersion.endereco_cobranca_cep || '');
      setEnderecoEntregaLogradouro(selectedVersion.endereco_entrega_logradouro || '');
      setEnderecoEntregaNumero(selectedVersion.endereco_entrega_numero || '');
      setEnderecoEntregaComplemento(selectedVersion.endereco_entrega_complemento || '');
      setEnderecoEntregaBairro(selectedVersion.endereco_entrega_bairro || '');
      setEnderecoEntregaCidade(selectedVersion.endereco_entrega_cidade || '');
      setEnderecoEntregaUf(selectedVersion.endereco_entrega_uf || '');
      setEnderecoEntregaCep(selectedVersion.endereco_entrega_cep || '');
      setGestorObraNome(selectedVersion.gestor_obra_nome || '');
      setGestorObraTelefone(selectedVersion.gestor_obra_telefone || '');
      setGestorObraEmail(selectedVersion.gestor_obra_email || '');
      setCondicoesPagamento(selectedVersion.condicoes_pagamento || '');
      setCondicoesPagamentoTerceiro(selectedVersion.condicoes_pagamento_terceiro || '');
      setPrevisaoMedicao(selectedVersion.previsao_medicao ? formatDate(selectedVersion.previsao_medicao) : '');
      setPrevisaoEntrega(selectedVersion.previsao_entrega ? formatDate(selectedVersion.previsao_entrega) : '');
      setObservacoes(selectedVersion.observacoes || '');
      setValorInstalacao(selectedVersion.valor_instalacao || 0);
      setValorFrete(selectedVersion.valor_frete || 0);
      setValorAbatimentoShowroom(selectedVersion.valor_abatimento_showroom || 0);
      setValorInstalacaoPVC(selectedVersion.valor_instalacao_pvc || 0);
      setValorFreteEsquadrias(selectedVersion.valor_frete_esquadrias || 0);
      setValorProjeto(selectedVersion.valor_projeto || 0);
      setValorVidrosSeparados(selectedVersion.valor_vidros_separados || 0);
      setValorExternasEsquadrias(selectedVersion.valor_externas_esquadrias || 0);
      setDescontoExternas(selectedVersion.desconto_externas || 0);
      setValorOutrosEsquadrias(selectedVersion.valor_outros_esquadrias || 0);
      setDescontoOutros(selectedVersion.desconto_outros || 0);
      setComissao(selectedVersion.comissao || 0);
      setEmailAssinaturaContrato(selectedVersion.email_assinatura_contrato || '');

      setEnderecoCobrancaEmail2(selectedVersion.endereco_cobranca_email2 || '');
      setEmailNotaFiscal2(selectedVersion.email_nota_fiscal2 || '');


      setObservacoesOutros(selectedVersion.observacoes_outros || '');



    }
  };




  // const moveCardToNewColumn = async (card_id, new_column_id) => {
  //   try {
  //     const response = await axios.post(`${apiUrl}/users/move-to-column`, {
  //       card_id,
  //       new_column_id
  //     });

  //     if (response.data) {

  //       setCards(prevCards =>
  //         prevCards.map(card =>
  //           card.card_id === card_id ? { ...card, column_id: new_column_id } : card
  //         )
  //       );

  //       return response.data;
  //     }
  //   } catch (error) {
  //     console.error('Erro ao mover o card:', error);
  //   }
  // };
  const moveCardToNewColumn = async (card_id, new_column_id) => {
    try {
      const response = await api.post(`/users/move-to-column`, {
        card_id,
        new_column_id
      });
  
      if (response.data) {
        setCards(prevCards =>
          prevCards.map(card =>
            card.card_id === card_id ? { ...card, column_id: new_column_id } : card
          )
        );
  
        return response.data;
      }
    } catch (error) {
      console.error("Erro ao mover o card:", error);
    }
  };
  


  // const handleSavePedido = async (e) => {
  //   e.preventDefault();


  //   const editableColumnIds = editableColumns.map(column => column.columnId); // Pegue o id de todas as colunas editáveis

  //   if (!editableColumnIds.includes(currentCardData.column_id)) {
  //     alert('Você não tem permissão para editar o pedido nesta coluna.');
  //     return;
  //   }


  //   if (typeof pedidoData.versao !== 'undefined') {
  //     if (!pedidoData.versao_ativa) {
  //       alert('Você só pode atualizar pedidos com a versão ativa.');
  //       return;
  //     }
  //   }


  //   if (statusPedido !== 'Parado') {
  //     alert('Você só pode atualizar pedidos com o status "Parado".');
  //     return;
  //   }

  //   if (!validateFields()) {
  //     alert('Por favor, corrija os erros antes de enviar.');
  //     return;
  //   }

  //   // Confirmação de ativação da versão
  //   const confirmActivation = window.confirm(`Confirma a solicitação do pedido?`);

  //   if (!confirmActivation) {
  //     // Se o usuário cancelar a confirmação, não continua com a ativação
  //     return;
  //   }


  //   try {


  //     const solicitacaoPedidosColumn = columns.find(column => column.name === 'Solicitação de Pedidos');

  //     moveCardToNewColumn(currentCardData.card_id, solicitacaoPedidosColumn.id)


  //     const response = await axios.post(`${apiUrl}/users/update-pedido`, {
  //       card_id: currentCardData.card_id,
  //       selected_version: currentVersion, // A versão atual selecionada
  //       updated_fields: {
  //         numero_pedido: numeroPedido,
  //         status_pedido: 'Em Andamento',
  //         nome_obra: nomeObra.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         representante: representante,
  //         nome_cliente: nomeCliente.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         cpf_cnpj: cpfCnpj,
  //         telefone_cliente: telefoneCliente,
  //         nome_contato: nomeContato.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         email_nota_fiscal: emailNotaFiscal.toLowerCase(),
  //         representante_legal_nome: representanteLegalNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         representante_legal_email: representanteLegalEmail.toLowerCase(),
  //         representante_legal_cpf: representanteLegalCpf,
  //         endereco_cobranca_responsavel: enderecoCobrancaResponsavel.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_cobranca_telefone: enderecoCobrancaTelefone,
  //         endereco_cobranca_email: enderecoCobrancaEmail.toLowerCase(),
  //         endereco_cobranca_logradouro: enderecoCobrancaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_cobranca_numero: enderecoCobrancaNumero,
  //         endereco_cobranca_complemento: enderecoCobrancaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_cobranca_bairro: enderecoCobrancaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_cobranca_cidade: enderecoCobrancaCidade,
  //         endereco_cobranca_uf: enderecoCobrancaUf,
  //         endereco_cobranca_cep: enderecoCobrancaCep,
  //         endereco_entrega_logradouro: enderecoEntregaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_entrega_numero: enderecoEntregaNumero,
  //         endereco_entrega_complemento: enderecoEntregaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_entrega_bairro: enderecoEntregaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         endereco_entrega_cidade: enderecoEntregaCidade,
  //         endereco_entrega_uf: enderecoEntregaUf,
  //         endereco_entrega_cep: enderecoEntregaCep,
  //         gestor_obra_nome: gestorObraNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         gestor_obra_telefone: gestorObraTelefone,
  //         gestor_obra_email: gestorObraEmail.toLowerCase(),
  //         condicoes_pagamento: condicoesPagamento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         condicoes_pagamento_terceiro: condicoesPagamentoTerceiro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         previsao_medicao: previsaoMedicao,
  //         previsao_entrega: previsaoEntrega,
  //         observacoes: observacoes.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
  //         valor_instalacao: valorInstalacao,
  //         valor_frete: valorFrete,
  //         valor_abatimento_showroom: valorAbatimentoShowroom,
  //         valor_instalacao_pvc: valorInstalacaoPVC,
  //         valor_frete_esquadrias: valorFreteEsquadrias,
  //         valor_projeto: valorProjeto,
  //         valor_vidros_separados: valorVidrosSeparados,
  //         valor_externas_esquadrias: valorExternasEsquadrias,
  //         desconto_externas: descontoExternas,
  //         valor_outros_esquadrias: valorOutrosEsquadrias,
  //         desconto_outros: descontoOutros,
  //         email_assinatura_contrato: emailAssinaturaContrato.toLowerCase(),
  //         comissao: comissao,

  //         endereco_cobranca_email2: enderecoCobrancaEmail2.toLowerCase(),
  //         email_nota_fiscal2: emailNotaFiscal2.toLowerCase(),

  //         observacoes_outros: observacoesOutros.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")


  //       }
  //     });

  //     if (response.data) {
  //       alert('Pedido atualizado com sucesso!');
  //       // Fechar o modal ou fazer qualquer ação necessária

  //       handleSendEmail(response.data)
  //     }
  //   } catch (error) {
  //     console.error('Erro ao salvar o pedido:', error);
  //     alert('Erro ao salvar o pedido. Por favor, tente novamente.');
  //   }
  // };



    const handleSavePedido = async (e) => {
    e.preventDefault();


    const editableColumnIds = editableColumns.map(column => column.columnId); // Pegue o id de todas as colunas editáveis

    if (!editableColumnIds.includes(currentCardData.column_id)) {
      alert('Você não tem permissão para editar o pedido nesta coluna.');
      return;
    }


    if (typeof pedidoData.versao !== 'undefined') {
      if (!pedidoData.versao_ativa) {
        alert('Você só pode atualizar pedidos com a versão ativa.');
        return;
      }
    }


    if (statusPedido !== 'Parado') {
      alert('Você só pode atualizar pedidos com o status "Parado".');
      return;
    }

    if (!validateFields()) {
      alert('Por favor, corrija os erros antes de enviar.');
      return;
    }

    // Confirmação de ativação da versão
    const confirmActivation = window.confirm(`Confirma a solicitação do pedido?`);

    if (!confirmActivation) {
      // Se o usuário cancelar a confirmação, não continua com a ativação
      return;
    }


    try {


      const solicitacaoPedidosColumn = columns.find(column => column.name === 'Solicitação de Pedidos');

      moveCardToNewColumn(currentCardData.card_id, solicitacaoPedidosColumn.id)


       const response = await api.post(`/users/update-pedido`, {
        card_id: currentCardData.card_id,
        selected_version: currentVersion, // A versão atual selecionada
        updated_fields: {
          numero_pedido: numeroPedido,
          status_pedido: 'Em Andamento',
          nome_obra: nomeObra.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          representante: representante,
          nome_cliente: nomeCliente.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          cpf_cnpj: cpfCnpj,
          telefone_cliente: telefoneCliente,
          nome_contato: nomeContato.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          email_nota_fiscal: emailNotaFiscal.toLowerCase(),
          representante_legal_nome: representanteLegalNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          representante_legal_email: representanteLegalEmail.toLowerCase(),
          representante_legal_cpf: representanteLegalCpf,
          endereco_cobranca_responsavel: enderecoCobrancaResponsavel.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_telefone: enderecoCobrancaTelefone,
          endereco_cobranca_email: enderecoCobrancaEmail.toLowerCase(),
          endereco_cobranca_logradouro: enderecoCobrancaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_numero: enderecoCobrancaNumero,
          endereco_cobranca_complemento: enderecoCobrancaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_bairro: enderecoCobrancaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_cidade: enderecoCobrancaCidade,
          endereco_cobranca_uf: enderecoCobrancaUf,
          endereco_cobranca_cep: enderecoCobrancaCep,
          endereco_entrega_logradouro: enderecoEntregaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_numero: enderecoEntregaNumero,
          endereco_entrega_complemento: enderecoEntregaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_bairro: enderecoEntregaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_cidade: enderecoEntregaCidade,
          endereco_entrega_uf: enderecoEntregaUf,
          endereco_entrega_cep: enderecoEntregaCep,
          gestor_obra_nome: gestorObraNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          gestor_obra_telefone: gestorObraTelefone,
          gestor_obra_email: gestorObraEmail.toLowerCase(),
          condicoes_pagamento: condicoesPagamento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          condicoes_pagamento_terceiro: condicoesPagamentoTerceiro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          previsao_medicao: previsaoMedicao,
          previsao_entrega: previsaoEntrega,
          observacoes: observacoes.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          valor_instalacao: valorInstalacao,
          valor_frete: valorFrete,
          valor_abatimento_showroom: valorAbatimentoShowroom,
          valor_instalacao_pvc: valorInstalacaoPVC,
          valor_frete_esquadrias: valorFreteEsquadrias,
          valor_projeto: valorProjeto,
          valor_vidros_separados: valorVidrosSeparados,
          valor_externas_esquadrias: valorExternasEsquadrias,
          desconto_externas: descontoExternas,
          valor_outros_esquadrias: valorOutrosEsquadrias,
          desconto_outros: descontoOutros,
          email_assinatura_contrato: emailAssinaturaContrato.toLowerCase(),
          comissao: comissao,

          endereco_cobranca_email2: enderecoCobrancaEmail2.toLowerCase(),
          email_nota_fiscal2: emailNotaFiscal2.toLowerCase(),

          observacoes_outros: observacoesOutros.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")


        }
      });

      if (response.data) {
        alert('Pedido atualizado com sucesso!');
        // Fechar o modal ou fazer qualquer ação necessária

        handleSendEmail(response.data)
      }
    } catch (error) {
      console.error('Erro ao salvar o pedido:', error);
      alert('Erro ao salvar o pedido. Por favor, tente novamente.');
    }
  };





  const handleEstadoChange = (e, tipo) => {
    const { value } = e.target;
    if (tipo === 'cobranca') {
      setEnderecoCobrancaUf(value);
      axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`)
        .then(response => {
          setCidadesCobranca(response.data.sort((a, b) => a.nome.localeCompare(b.nome)));
        });
    } else {
      setEnderecoEntregaUf(value);
      axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`)
        .then(response => {
          setCidadesEntrega(response.data.sort((a, b) => a.nome.localeCompare(b.nome)));
        });
    }
  };

  const getUsernameById = (entityId) => {


    let convertEntityId = parseInt(entityId)
    if (!user) {
      return 'Erro: Dados necessários não estão disponíveis.';
    }

    const entidade = listAllUsers.find(afilhado => afilhado.id === convertEntityId);
    if (entidade && entidade.username) {
      return entidade.username;
    }

    if (convertEntityId === user.id) {
      return user.username;
    }

    return 'id não encontrado';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Retorna a data no formato 'YYYY-MM-DD'
  };

  const calculateTotalProdutos = () => {
    return (
      parseFloat(valorFinalExternas || 0) +
      parseFloat(valorFinalOutros || 0)
    );
  };

  const calculateTotalServicos = () => {
    return (
      parseFloat(valorInstalacao || 0) +
      parseFloat(valorFrete || 0) +
      parseFloat(valorAbatimentoShowroom || 0)
    );
  };

  const calculateTotalTerceiros = () => {
    return (
      parseFloat(valorInstalacaoPVC || 0) +
      parseFloat(valorFreteEsquadrias || 0) +
      parseFloat(valorProjeto || 0) +
      parseFloat(valorVidrosSeparados || 0)
    );
  };

  useEffect(() => {
    setValorFinalExternas((parseFloat(valorExternasEsquadrias) - (parseFloat(valorExternasEsquadrias) * parseFloat(descontoExternas) / 100)).toFixed(2));
  }, [valorExternasEsquadrias, descontoExternas]);

  useEffect(() => {
    setValorFinalOutros((parseFloat(valorOutrosEsquadrias) - (parseFloat(valorOutrosEsquadrias) * parseFloat(descontoOutros) / 100)).toFixed(2));
  }, [valorOutrosEsquadrias, descontoOutros]);

  const formatCurrency = (value) => {
    // Converte o valor para número se estiver em formato string
    const numericValue = parseFloat(value);
    // Retorna o valor formatado como moeda brasileira
    return numericValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };




  const formatDateForm = (date) => {
    if (!date) return '';
    const data = new Date(date);
    return data.toLocaleDateString('pt-BR'); // Formato dia/mês/ano
  };


  const handleSendEmail = async (pedidoData) => {
    // Calcular os valores finais novamente antes de enviar o email
    const finalExternas = (
      parseFloat(pedidoData?.valor_externas_esquadrias || 0) -
      (parseFloat(pedidoData?.valor_externas_esquadrias || 0) * parseFloat(pedidoData?.desconto_externas || 0) / 100)
    ).toFixed(2);

    const finalOutros = (
      parseFloat(pedidoData?.valor_outros_esquadrias || 0) -
      (parseFloat(pedidoData?.valor_outros_esquadrias || 0) * parseFloat(pedidoData?.desconto_outros || 0) / 100)
    ).toFixed(2);

    const emailData = {
      to: empresa?.email_solicitacao_pedidos || 'email@default.com',
      subject: 'Solicitação de Pedido',
      text: `
        Uma nova solicitação de pedido foi criada!
        Seguintes informações:
  
        Versão Ativa: ${pedidoData?.versao || 'N/A'}
  
        **Informações do Pedido**
        Número do Pedido: ${pedidoData?.numero_pedido || 'N/A'}
        Status do Pedido: ${pedidoData?.status_pedido || 'N/A'}
        Nome da Obra: ${pedidoData?.nome_obra || 'N/A'}
        Representante: ${getUsernameById(pedidoData?.representante || '') || 'N/A'}
  
        **Dados do Cliente**
        Nome do Cliente: ${pedidoData?.nome_cliente || 'N/A'}
        CPF/CNPJ: ${pedidoData?.cpf_cnpj || 'N/A'}
        Telefone do Cliente: ${pedidoData?.telefone_cliente || 'N/A'}
        Nome para Contato: ${pedidoData?.nome_contato || 'N/A'}
        Email para Nota Fiscal: ${pedidoData?.email_nota_fiscal || 'N/A'}
  
        **Dados do Representante Legal**
        Nome: ${pedidoData?.representante_legal_nome || 'N/A'}
        Email: ${pedidoData?.representante_legal_email || 'N/A'}
        CPF: ${pedidoData?.representante_legal_cpf || 'N/A'}
  
        **Endereço de Cobrança**
        Responsável: ${pedidoData?.endereco_cobranca_responsavel || 'N/A'}
        Telefone: ${pedidoData?.endereco_cobranca_telefone || 'N/A'}
        Email: ${pedidoData?.endereco_cobranca_email || 'N/A'}
        Logradouro: ${pedidoData?.endereco_cobranca_logradouro || 'N/A'}
        Número: ${pedidoData?.endereco_cobranca_numero || 'N/A'}
        Complemento: ${pedidoData?.endereco_cobranca_complemento || 'N/A'}
        Bairro: ${pedidoData?.endereco_cobranca_bairro || 'N/A'}
        Cidade: ${pedidoData?.endereco_cobranca_cidade || 'N/A'}
        Estado: ${pedidoData?.endereco_cobranca_uf || 'N/A'}
        CEP: ${pedidoData?.endereco_cobranca_cep || 'N/A'}
        Condomínio: ${pedidoData?.endereco_cobranca_condominio || 'N/A'}
  
        **Endereço de Entrega**
        Logradouro: ${pedidoData?.endereco_entrega_logradouro || 'N/A'}
        Número: ${pedidoData?.endereco_entrega_numero || 'N/A'}
        Complemento: ${pedidoData?.endereco_entrega_complemento || 'N/A'}
        Bairro: ${pedidoData?.endereco_entrega_bairro || 'N/A'}
        Cidade: ${pedidoData?.endereco_entrega_cidade || 'N/A'}
        Estado: ${pedidoData?.endereco_entrega_uf || 'N/A'}
        CEP: ${pedidoData?.endereco_entrega_cep || 'N/A'}
        Condomínio: ${pedidoData?.endereco_entrega_condominio || 'N/A'}
  
        **Gestor da Obra**
        Nome: ${pedidoData?.gestor_obra_nome || 'N/A'}
        Telefone: ${pedidoData?.gestor_obra_telefone || 'N/A'}
        Email: ${pedidoData?.gestor_obra_email || 'N/A'}
  
        **Valores dos Produtos**
        Externas:
        Valor Esquadrias: ${formatCurrency(pedidoData?.valor_externas_esquadrias || 0)}
        Desconto: ${pedidoData?.desconto_externas || 0}%
        Valor Final: ${formatCurrency(finalExternas)}
  
        Outros:
        Valor Esquadrias: ${formatCurrency(pedidoData?.valor_outros_esquadrias || 0)}
        Desconto: ${pedidoData?.desconto_outros || 0}%
        Valor Final: ${formatCurrency(finalOutros)}
  
        Valor Total Bazze: ${formatCurrency(calculateTotalProdutos() + calculateTotalServicos())}
  
        **Valores dos Serviços Cobrados pela Bazze**
        Instalação: ${formatCurrency(pedidoData?.valor_instalacao || 0)}
        Frete: ${formatCurrency(pedidoData?.valor_frete || 0)}
        Abatimento Showroom: ${formatCurrency(pedidoData?.valor_abatimento_showroom || 0)}
  
        **Valores Pagos pelo Cliente Diretamente ao Terceiro**
        Instalação PVC: ${formatCurrency(pedidoData?.valor_instalacao_pvc || 0)}
        Frete Esquadrias: ${formatCurrency(pedidoData?.valor_frete_esquadrias || 0)}
        Projeto: ${formatCurrency(pedidoData?.valor_projeto || 0)}
        Vidros Separados: ${formatCurrency(pedidoData?.valor_vidros_separados || 0)}
  
        Valor Total de Terceiros: ${formatCurrency(calculateTotalTerceiros())}
        Valor Total do Contrato: ${formatCurrency(calculateTotalProdutos() + calculateTotalServicos() + calculateTotalTerceiros())}
        
        **Condições de Pagamento**
        ${pedidoData?.condicoes_pagamento || 'N/A'}
  
        **Condições de Pagamento Terceiro**
        ${pedidoData?.condicoes_pagamento_terceiro || 'N/A'}
  
        **Previsões**
        Previsão de Medição: ${formatDateForm(pedidoData?.previsao_medicao) || 'N/A'}
        Previsão de Entrega: ${formatDateForm(pedidoData?.previsao_entrega) || 'N/A'}
  
        **Observações**
        ${pedidoData?.observacoes || 'N/A'}
      `
    };

    try {
      // const response = await axios.post(`${apiUrl}/users/send-email/${empresa?.id || 0}`, emailData);
      const response = await api.post(`/users/send-email/${empresa?.id || 0}`, emailData);

      alert('Email com as informações enviado para o setor responsável!');
      setOpenClosePedidosModal(false);
    } catch (error) {
      console.error('Erro ao enviar o email:', error);
      alert('ERRO AO ENVIAR EMAIL PARA O SETOR DE PEDIDOS!!! ENTRE EM CONTATO COM O SETOR RESPONSÁVEL!!!');
    }
  };






  const handleKeyDown = (e) => {
    if (e.key === ' ') {

      e.stopPropagation();
    }
  };


  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  function inputPreenchido(valor) {
    if (valor && valor !== '') {
      return 'solid 1px blue'; // Retorna o valor para o caso preenchido
    } else {
      return 'solid 1px red'; // Retorna o valor para o caso não preenchido
    }
  }





  async function addNewVersion(currentCardData) {

    // if (statusPedido !== 'Parado') {
    //   alert('Você só pode ativar uma versão se o status do pedido for "Parado".');
    //   return;
    // }



    const editableColumnIds = editableColumns.map(column => column.columnId); // Pegue o id de todas as colunas editáveis

    if (!editableColumnIds.includes(currentCardData.column_id)) {
      alert('Você não tem permissão para editar o pedido nesta coluna.');
      return;
    }


    // Confirmação de ativação da versão
    const confirmActivation = window.confirm(`Você tem certeza que deseja criar uma nova versão?`);

    if (!confirmActivation) {
      // Se o usuário cancelar a confirmação, não continua com a ativação
      return;
    }



    try {
      // const response = await axios.post(`${apiUrl}/users/copy-version`, {
        const response = await api.post(`/users/copy-version`, {

        card_id: currentCardData.card_id,
        selected_version: currentVersion

      });

      if (response.data) {
        alert('Nova versão criada com sucesso!');
        // Atualiza as versões na interface para refletir a nova versãos
        setVersions(prevVersions => [...prevVersions, response.data]);
      }
    } catch (error) {
      console.error('Erro ao criar uma nova versão:', error);
      alert('Erro ao criar uma nova versão. Por favor, tente novamente.');
    }
  }


  const handleActivateVersion = async (versao) => {

    const editableColumnIds = editableColumns.map(column => column.columnId); // Pegue o id de todas as colunas editáveis

    if (!editableColumnIds.includes(currentCardData.column_id)) {
      alert('Você não tem permissão para editar o pedido nesta coluna.');
      return;
    }


    if (statusPedido !== 'Parado') {
      alert('Você só pode ativar uma versão se o status do pedido for "Parado".');
      return;
    }

    const confirmActivation = window.confirm(`Você tem certeza que deseja ativar a versão ${versao}?`);

    if (!confirmActivation) {
      return;
    }

    try {
      // Realiza a requisição para ativar a versão no backend
      // const response = await axios.post(`${apiUrl}/users/activate-version`, {
        const response = await api.post(`/users/activate-version`, {

        card_id: currentCardData.card_id,
        versao: versao
      });

      // Atualiza o estado local para refletir a ativação da versão
      setCurrentVersion(versao);

      // Atualiza o estado das versões: define a versão ativa e desativa as outras
      setVersions(prevVersions =>
        prevVersions.map(v => ({
          ...v,
          versao_ativa: v.versao === versao // Define como ativa a versão selecionada
        }))
      );


      // Atualiza apenas o campo versao_ativa de pedidoData
      setPedidoData(prevPedidoData => ({
        ...prevPedidoData,
        versao_ativa: true
      }));


      alert('Versão ativada com sucesso!');
    } catch (error) {
      console.error('Erro ao ativar a versão:', error);

      if (error.response && error.response.data) {
        alert(`Erro: ${error.response.data.message}`);
      } else {
        alert('Erro ao ativar a versão. Por favor, tente novamente.');
      }
    }
  };







  const updatePedido = async (e) => {
    e.preventDefault();


    if (!validateFields()) {
      alert('Por favor, corrija os erros antes de enviar.');
      return;
    }

    if (typeof pedidoData.versao !== 'undefined') {
      if (!pedidoData.versao_ativa) {
        alert('Você só pode atualizar pedidos com a versão ativa.');
        return;
      }
    }

    // Confirmação de ativação da versão
    const confirmActivation = window.confirm(`Confirma a atualização do pedido?`);

    if (!confirmActivation) {
      // Se o usuário cancelar a confirmação, não continua com a ativação
      return;
    }


    // Função para normalizar os valores numéricos
    const normalizeNumber = (value) => {
      if (typeof value === 'string') {
        // Remove separador de milhar (pontos) e troca a vírgula pelo ponto (para decimais)
        return parseFloat(value.replace(/\./g, '').replace(',', '.'));
      }
      return value;
    };


    try {





      // const response = await axios.post(`${apiUrl}/users/update-pedido`, {
        const response = await api.post(`/users/update-pedido`, {

        card_id: currentCardData.card_id,
        selected_version: currentVersion, // A versão atual selecionada
        updated_fields: {
          numero_pedido: numeroPedido,
          status_pedido: 'Em Andamento',
          nome_obra: nomeObra.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          representante: representante,
          nome_cliente: nomeCliente.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          cpf_cnpj: cpfCnpj,
          telefone_cliente: telefoneCliente,
          nome_contato: nomeContato.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          email_nota_fiscal: emailNotaFiscal.toLowerCase(),
          representante_legal_nome: representanteLegalNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          representante_legal_email: representanteLegalEmail.toLowerCase(),
          representante_legal_cpf: representanteLegalCpf,
          endereco_cobranca_responsavel: enderecoCobrancaResponsavel.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_telefone: enderecoCobrancaTelefone,
          endereco_cobranca_email: enderecoCobrancaEmail.toLowerCase(),
          endereco_cobranca_logradouro: enderecoCobrancaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_numero: enderecoCobrancaNumero,
          endereco_cobranca_complemento: enderecoCobrancaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_bairro: enderecoCobrancaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_cobranca_cidade: enderecoCobrancaCidade,
          endereco_cobranca_uf: enderecoCobrancaUf,
          endereco_cobranca_cep: enderecoCobrancaCep,
          endereco_entrega_logradouro: enderecoEntregaLogradouro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_numero: enderecoEntregaNumero,
          endereco_entrega_complemento: enderecoEntregaComplemento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_bairro: enderecoEntregaBairro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          endereco_entrega_cidade: enderecoEntregaCidade,
          endereco_entrega_uf: enderecoEntregaUf,
          endereco_entrega_cep: enderecoEntregaCep,
          gestor_obra_nome: gestorObraNome.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          gestor_obra_telefone: gestorObraTelefone,
          gestor_obra_email: gestorObraEmail.toLowerCase(),
          condicoes_pagamento: condicoesPagamento.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          condicoes_pagamento_terceiro: condicoesPagamentoTerceiro.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          previsao_medicao: previsaoMedicao,
          previsao_entrega: previsaoEntrega,
          observacoes: observacoes.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
          valor_instalacao: valorInstalacao,
          valor_frete: valorFrete,
          valor_abatimento_showroom: valorAbatimentoShowroom,
          valor_instalacao_pvc: valorInstalacaoPVC,
          valor_frete_esquadrias: valorFreteEsquadrias,
          valor_projeto: valorProjeto,
          valor_vidros_separados: valorVidrosSeparados,
          valor_externas_esquadrias: valorExternasEsquadrias,
          desconto_externas: descontoExternas,
          valor_outros_esquadrias: valorOutrosEsquadrias,
          desconto_outros: descontoOutros,
          email_assinatura_contrato: emailAssinaturaContrato.toLowerCase(),
          comissao: comissao,

          endereco_cobranca_email2: enderecoCobrancaEmail2.toLowerCase(),
          email_nota_fiscal2: emailNotaFiscal2.toLowerCase(),

          observacoes_outros: observacoesOutros.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        }
      });


      if (response.data) {
        alert('Pedido atualizado com sucesso!');
        // Fechar o modal ou fazer qualquer ação necessária
        addHistoricoCardContext(`Formulario de Pedido alterado por: ${getUsernameById(user.id)} `, currentCardData.card_id, user.id);

        setOpenClosePedidosModal(false);

      }
    } catch (error) {
      console.error('Erro ao salvar o pedido:', error);
      alert('Erro ao salvar o pedido. Por favor, tente novamente.');
    }
  };



   // Função para salvar localmente os dados do formulário no localStorage
   const saveFormToLocalStorage = () => {
    if (!currentCardData.card_id) {
      alert('ID do card não encontrado.');
      return;
    }

    const formData = {
      numeroPedido,
      statusPedido,
      dataStatus,
      nomeObra,
      representante,
      nomeCliente,
      cpfCnpj,
      telefoneCliente,
      nomeContato,
      emailNotaFiscal,
      representanteLegalNome,
      representanteLegalEmail,
      representanteLegalCpf,
      enderecoCobrancaResponsavel,
      enderecoCobrancaTelefone,
      enderecoCobrancaEmail,
      enderecoCobrancaLogradouro,
      enderecoCobrancaNumero,
      enderecoCobrancaComplemento,
      enderecoCobrancaBairro,
      enderecoCobrancaCidade,
      enderecoCobrancaUf,
      enderecoCobrancaCep,
      enderecoCobrancaCondominio,
      enderecoEntregaLogradouro,
      enderecoEntregaNumero,
      enderecoEntregaComplemento,
      enderecoEntregaBairro,
      enderecoEntregaCidade,
      enderecoEntregaUf,
      enderecoEntregaCep,
      enderecoEntregaCondominio,
      gestorObraNome,
      gestorObraTelefone,
      gestorObraEmail,
      condicoesPagamento,
      condicoesPagamentoTerceiro,
      previsaoMedicao,
      previsaoEntrega,
      observacoes,
      valorInstalacao,
      valorFrete,
      valorAbatimentoShowroom,
      valorFreteEsquadrias,
      valorProjeto,
      valorVidrosSeparados,
      valorExternasEsquadrias,
      descontoExternas,
      valorFinalExternas,
      valorOutrosEsquadrias,
      descontoOutros,
      valorFinalOutros,
      valorInstalacaoPVC,
      emailAssinaturaContrato,
      comissao,
      enderecoCobrancaEmail2,
      emailNotaFiscal2,
      observacoesOutros,
    };

    localStorage.setItem(`pedido_${currentCardData.card_id}`, JSON.stringify(formData));
    alert('Formulário salvo localmente.');
  };

  // Função para carregar os dados do formulário do localStorage
  const loadFormFromLocalStorage = () => {
    if (!currentCardData.card_id) {
      alert('ID do card não encontrado.');
      return;
    }

    const savedData = localStorage.getItem(`pedido_${currentCardData.card_id}`);
    if (savedData) {
      const formData = JSON.parse(savedData);
      // Atualiza os estados com os dados do armazenamento local
      setNumeroPedido(formData.numeroPedido || '');
      setStatusPedido(formData.statusPedido || 'Parado');
      setDataStatus(formData.dataStatus || null);
      setNomeObra(formData.nomeObra || '');
      setRepresentante(formData.representante || 0);
      setNomeCliente(formData.nomeCliente || '');
      setCpfCnpj(formData.cpfCnpj || '');
      setTelefoneCliente(formData.telefoneCliente || '');
      setNomeContato(formData.nomeContato || '');
      setEmailNotaFiscal(formData.emailNotaFiscal || '');
      setRepresentanteLegalNome(formData.representanteLegalNome || '');
      setRepresentanteLegalEmail(formData.representanteLegalEmail || '');
      setRepresentanteLegalCpf(formData.representanteLegalCpf || '');
      setEnderecoCobrancaResponsavel(formData.enderecoCobrancaResponsavel || '');
      setEnderecoCobrancaTelefone(formData.enderecoCobrancaTelefone || '');
      setEnderecoCobrancaEmail(formData.enderecoCobrancaEmail || '');
      setEnderecoCobrancaLogradouro(formData.enderecoCobrancaLogradouro || '');
      setEnderecoCobrancaNumero(formData.enderecoCobrancaNumero || '');
      setEnderecoCobrancaComplemento(formData.enderecoCobrancaComplemento || '');
      setEnderecoCobrancaBairro(formData.enderecoCobrancaBairro || '');
      setEnderecoCobrancaCidade(formData.enderecoCobrancaCidade || '');
      setEnderecoCobrancaUf(formData.enderecoCobrancaUf || '');
      setEnderecoCobrancaCep(formData.enderecoCobrancaCep || '');
      setEnderecoCobrancaCondominio(formData.enderecoCobrancaCondominio || '');
      setEnderecoEntregaLogradouro(formData.enderecoEntregaLogradouro || '');
      setEnderecoEntregaNumero(formData.enderecoEntregaNumero || '');
      setEnderecoEntregaComplemento(formData.enderecoEntregaComplemento || '');
      setEnderecoEntregaBairro(formData.enderecoEntregaBairro || '');
      setEnderecoEntregaCidade(formData.enderecoEntregaCidade || '');
      setEnderecoEntregaUf(formData.enderecoEntregaUf || '');
      setEnderecoEntregaCep(formData.enderecoEntregaCep || '');
      setEnderecoEntregaCondominio(formData.enderecoEntregaCondominio || '');
      setGestorObraNome(formData.gestorObraNome || '');
      setGestorObraTelefone(formData.gestorObraTelefone || '');
      setGestorObraEmail(formData.gestorObraEmail || '');
      setCondicoesPagamento(formData.condicoesPagamento || '');
      setCondicoesPagamentoTerceiro(formData.condicoesPagamentoTerceiro || '');
      setPrevisaoMedicao(formData.previsaoMedicao || null);
      setPrevisaoEntrega(formData.previsaoEntrega || null);
      setObservacoes(formData.observacoes || '');
      setValorInstalacao(formData.valorInstalacao || 0);
      setValorFrete(formData.valorFrete || 0);
      setValorAbatimentoShowroom(formData.valorAbatimentoShowroom || 0);
      setValorFreteEsquadrias(formData.valorFreteEsquadrias || 0);
      setValorProjeto(formData.valorProjeto || 0);
      setValorVidrosSeparados(formData.valorVidrosSeparados || 0);
      setValorExternasEsquadrias(formData.valorExternasEsquadrias || 0);
      setDescontoExternas(formData.descontoExternas || 0);
      setValorFinalExternas(formData.valorFinalExternas || 0);
      setValorOutrosEsquadrias(formData.valorOutrosEsquadrias || 0);
      setDescontoOutros(formData.descontoOutros || 0);
      setValorFinalOutros(formData.valorFinalOutros || 0);
      setValorInstalacaoPVC(formData.valorInstalacaoPVC || 0);
      setEmailAssinaturaContrato(formData.emailAssinaturaContrato || '');
      setComissao(formData.comissao || 0);
      setEnderecoCobrancaEmail2(formData.enderecoCobrancaEmail2 || '');
      setEmailNotaFiscal2(formData.emailNotaFiscal2 || '');
      setObservacoesOutros(formData.observacoesOutros || '');
      alert('Formulário carregado com sucesso.');
    } else {
      alert('Nenhum dado salvo encontrado para este card.');
    }
  };






   // Função para excluir os dados salvos do localStorage
   const deleteFormFromLocalStorage = () => {
    if (!currentCardData.card_id) {
      alert('ID do card não encontrado.');
      return;
    }

    const key = `pedido_${currentCardData.card_id}`;
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
      alert('Dados temporários excluídos com sucesso.');
    } else {
      alert('Nenhum dado salvo encontrado para este card.');
    }
  };


  return (

    <div className='modal-pedidos'>

      <div className="pedido-form-container" onClick={(e) => e.stopPropagation()}>


        <button className="close-button-form-pedidos" onClick={(e) => setOpenClosePedidosModal(false)}>X</button>

        <div className='header-form-pedido-container'>

          <h3>Formulário de Pedidos</h3>
        </div>




        <div className="pedido-form" onSubmit={handleSavePedido} autoComplete="off">


          <div className='versions-container-pedidos'>
            <ul className='versions-container-pedidos-list'>
              {versions
                .sort((a, b) => a.versao - b.versao) // Ordenando pela versão (ordem crescente)
                .map((version) => (

                  <div className='item-list-version-pedido-column'>

                    <li className='versions-pedidos-list-item' style={{ backgroundColor: version.versao === currentVersion ? 'rgb(42, 179, 8)' : '' }} key={version.id} onClick={() => handleVersionSelect(version.versao, version)}>
                      Versão {version.versao}
                      {version.versao_ativa && (
                        <IoStarSharp className='item-list-version-pedido-label' />
                      )}
                    </li>

                  </div>

                ))}
            </ul>


            {pedidoData.versao >= 1 && (

              <button className='btn-add-versions-pedidos' onClick={() => addNewVersion(currentCardData)}>Nova Versão</button>
            )}


          </div>

          {pedidoData.versao && !pedidoData.versao_ativa && (
            <button className="btn-activate-version-ativa" onClick={() => handleActivateVersion(currentVersion)}>
              Ativar Versão
            </button>
          )}



          <h3>Informações do Pedido</h3>
          <label>Número do Orçamento</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="numero_pedido" value={numeroPedido} onChange={(e) => setNumeroPedido(e.target.value)} style={{ border: inputPreenchido(numeroPedido) }} />

          {errors.numeroPedido && <span className="error">{errors.numeroPedido}</span>}

          <label>Status do Pedido - {dataStatus ? dataStatus : ''}</label>
          <select disabled={!getAccessLevel('editePedido')} name="status_pedido" value={statusPedido} onChange={(e) => setStatusPedido(e.target.value)} >
            <option value="Parado">Parado</option>
            <option value="Em Andamento">Em Andamento</option>
            <option value="Pronto">Pronto</option>
          </select>
          <label>Nome da Obra</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="nome_obra" value={nomeObra} onChange={(e) => setNomeObra(e.target.value)} style={{ border: inputPreenchido(nomeObra) }} />

          {errors.nomeObra && <span className="error">{errors.nomeObra}</span>}


          <label>Representante</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="int" name="representante" value={getUsernameById(representante)} onChange={(e) => setRepresentante(e.target.value)} />

          {errors.representante && <span className="error">{errors.representante}</span>}

          <h3>Dados do Faturamento</h3>
          <label>Nome do Cliente</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="nome_cliente" value={nomeCliente} onChange={(e) => setNomeCliente(e.target.value)} style={{ border: inputPreenchido(nomeCliente) }} />

          {errors.nomeCliente && <span className="error">{errors.nomeCliente}</span>}

          <label>CPF/CNPJ</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="cpf_cnpj" value={cpfCnpj} onChange={(e) => setCpfCnpj(e.target.value)} style={{ border: inputPreenchido(cpfCnpj) }} />

          {errors.cpfCnpj && <span className="error">{errors.cpfCnpj}</span>}


          <label>Telefone</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="telefone_cliente" value={telefoneCliente} onChange={(e) => setTelefoneCliente(e.target.value)} style={{ border: inputPreenchido(telefoneCliente) }} />

          {errors.telefoneCliente && <span className="error">{errors.telefoneCliente}</span>}


          <label>Nome para Contato</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="nome_contato" value={nomeContato} onChange={(e) => setNomeContato(e.target.value)} style={{ border: inputPreenchido(nomeContato) }} />

          {errors.nomeContato && <span className="error">{errors.nomeContato}</span>}



          <label>Email para Nota Fiscal</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="email_nota_fiscal" value={emailNotaFiscal} onChange={(e) => setEmailNotaFiscal(e.target.value)} style={{ border: inputPreenchido(emailNotaFiscal) }} />

          {errors.emailNotaFiscal && <span className="error">{errors.emailNotaFiscal}</span>}

          <label>Email para Nota Fiscal - Opcional</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="email_nota_fiscal2" value={emailNotaFiscal2} onChange={(e) => setEmailNotaFiscal2(e.target.value)} />


          <h3>Dados do Representante Legal</h3>
          <label>Nome</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="representante_legal_nome" value={representanteLegalNome} onChange={(e) => setRepresentanteLegalNome(e.target.value)} />

          {errors.representanteLegalNome && <span className="error">{errors.representanteLegalNome}</span>}



          <label>Email</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="representante_legal_email" value={representanteLegalEmail} onChange={(e) => setRepresentanteLegalEmail(e.target.value)} />

          {errors.representanteLegalEmail && <span className="error">{errors.representanteLegalEmail}</span>}


          <label>CPF</label>
          <input autoComplete="off" onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="representante_legal_cpf" value={representanteLegalCpf} onChange={(e) => setRepresentanteLegalCpf(e.target.value)} />

          {errors.representanteLegalCpf && <span className="error">{errors.representanteLegalCpf}</span>}


          <h3>Endereço de Cobrança</h3>
          <label>Responsável</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_responsavel" value={enderecoCobrancaResponsavel} onChange={(e) => setEnderecoCobrancaResponsavel(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaResponsavel) }} />

          {errors.enderecoCobrancaResponsavel && <span className="error">{errors.enderecoCobrancaResponsavel}</span>}


          <label>Telefone</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_telefone" value={enderecoCobrancaTelefone} onChange={(e) => setEnderecoCobrancaTelefone(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaTelefone) }} />

          {errors.enderecoCobrancaTelefone && <span className="error">{errors.enderecoCobrancaTelefone}</span>}


          <label>Email para Boleto</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="endereco_cobranca_email" value={enderecoCobrancaEmail} onChange={(e) => setEnderecoCobrancaEmail(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaEmail) }} />

          {errors.enderecoCobrancaEmail && <span className="error">{errors.enderecoCobrancaEmail}</span>}

          <label>Email para Boleto - Opcional</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="endereco_cobranca_email2" value={enderecoCobrancaEmail2} onChange={(e) => setEnderecoCobrancaEmail2(e.target.value)} />


          <label>Logradouro</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_logradouro" value={enderecoCobrancaLogradouro} onChange={(e) => setEnderecoCobrancaLogradouro(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaLogradouro) }} />

          {errors.enderecoCobrancaLogradouro && <span className="error">{errors.enderecoCobrancaLogradouro}</span>}


          <label>Número</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_numero" value={enderecoCobrancaNumero} onChange={(e) => setEnderecoCobrancaNumero(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaNumero) }} />

          {errors.enderecoCobrancaNumero && <span className="error">{errors.enderecoCobrancaNumero}</span>}


          <label>Complemento</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_complemento" value={enderecoCobrancaComplemento} onChange={(e) => setEnderecoCobrancaComplemento(e.target.value)} />
          <label>Bairro</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_bairro" value={enderecoCobrancaBairro} onChange={(e) => setEnderecoCobrancaBairro(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaBairro) }} />

          {errors.enderecoCobrancaBairro && <span className="error">{errors.enderecoCobrancaBairro}</span>}

          <label>Estado</label>
          <select name="endereco_cobranca_uf" value={enderecoCobrancaUf} onChange={(e) => handleEstadoChange(e, 'cobranca')} className="select-input">
            <option value="">Selecione o Estado</option>
            {estados.map(estado => (
              <option key={estado.id} value={estado.sigla}>{estado.nome}</option>
            ))}
          </select>

          {errors.enderecoCobrancaUf && <span className="error">{errors.enderecoCobrancaUf}</span>}


          <label>Cidade</label>
          <select name="endereco_cobranca_cidade" value={enderecoCobrancaCidade} onChange={(e) => setEnderecoCobrancaCidade(e.target.value)} className="select-input">
            <option value="">Selecione a Cidade</option>
            {cidadesCobranca.map(cidade => (
              <option key={cidade.id} value={cidade.nome}>{cidade.nome}</option>
            ))}
          </select>

          {errors.enderecoCobrancaCidade && <span className="error">{errors.enderecoCobrancaCidade}</span>}


          <label>CEP</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_cep" value={enderecoCobrancaCep} onChange={(e) => setEnderecoCobrancaCep(e.target.value)} style={{ border: inputPreenchido(enderecoCobrancaCep) }} />

          {errors.enderecoCobrancaCep && <span className="error">{errors.enderecoCobrancaCep}</span>}


          <label>Endereço dentro do condomínio</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_cobranca_condominio" value={enderecoCobrancaCondominio} onChange={(e) => setEnderecoCobrancaCondominio(e.target.value)} />

          <h3>Endereço de Entrega</h3>
          <label>Logradouro</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_logradouro" value={enderecoEntregaLogradouro} onChange={(e) => setEnderecoEntregaLogradouro(e.target.value)} style={{ border: inputPreenchido(enderecoEntregaLogradouro) }} />

          {errors.enderecoEntregaLogradouro && <span className="error">{errors.enderecoEntregaLogradouro}</span>}


          <label>Número</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_numero" value={enderecoEntregaNumero} onChange={(e) => setEnderecoEntregaNumero(e.target.value)} style={{ border: inputPreenchido(enderecoEntregaNumero) }} />

          {errors.enderecoEntregaNumero && <span className="error">{errors.enderecoEntregaNumero}</span>}

          <label>Complemento</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_complemento" value={enderecoEntregaComplemento} onChange={(e) => setEnderecoEntregaComplemento(e.target.value)} />
          <label>Bairro</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_bairro" value={enderecoEntregaBairro} onChange={(e) => setEnderecoEntregaBairro(e.target.value)} style={{ border: inputPreenchido(enderecoEntregaBairro) }} />

          {errors.enderecoEntregaBairro && <span className="error">{errors.enderecoEntregaBairro}</span>}

          <label>Estado</label>
          <select name="endereco_entrega_uf" value={enderecoEntregaUf} onChange={(e) => handleEstadoChange(e, 'entrega')} className="select-input">
            <option value="">Selecione o Estado</option>
            {estados.map(estado => (
              <option key={estado.id} value={estado.sigla}>{estado.nome}</option>
            ))}
          </select>

          {errors.enderecoEntregaUf && <span className="error">{errors.enderecoEntregaUf}</span>}

          <label>Cidade</label>
          <select name="endereco_entrega_cidade" value={enderecoEntregaCidade} onChange={(e) => setEnderecoEntregaCidade(e.target.value)} className="select-input">
            <option value="">Selecione a Cidade</option>
            {cidadesEntrega.map(cidade => (
              <option key={cidade.id} value={cidade.nome}>{cidade.nome}</option>
            ))}
          </select>

          {errors.enderecoEntregaCidade && <span className="error">{errors.enderecoEntregaCidade}</span>}


          <label>CEP</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_cep" value={enderecoEntregaCep} onChange={(e) => setEnderecoEntregaCep(e.target.value)} style={{ border: inputPreenchido(enderecoEntregaCep) }} />

          {errors.enderecoEntregaCep && <span className="error">{errors.enderecoEntregaCep}</span>}


          <label>Endereço dentro do condomínio</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="endereco_entrega_condominio" value={enderecoEntregaCondominio} onChange={(e) => setEnderecoEntregaCondominio(e.target.value)} />

          <h3>Gestores da Obra</h3>
          <label>Nome</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="gestor_obra_nome" value={gestorObraNome} onChange={(e) => setGestorObraNome(e.target.value)} />

          {errors.gestorObraNome && <span className="error">{errors.gestorObraNome}</span>}


          <label>Telefone</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="text" name="gestor_obra_telefone" value={gestorObraTelefone} onChange={(e) => setGestorObraTelefone(e.target.value)} />

          {errors.gestorObraTelefone && <span className="error">{errors.gestorObraTelefone}</span>}


          <label>Email</label>
          <input onClick={handleFieldClick} readOnly={!canEditField()} type="email" name="gestor_obra_email" value={gestorObraEmail} onChange={(e) => setGestorObraEmail(e.target.value)} />

          {errors.gestorObraEmail && <span className="error">{errors.gestorObraEmail}</span>}

          <h3>Assinatura de Contrato</h3>

          <label>Email para Assinatura de Contrato</label>
          <input
            onClick={handleFieldClick} readOnly={!canEditField()}
            type="email"
            name="email_assinatura_contrato"
            value={emailAssinaturaContrato}
            onChange={(e) => setEmailAssinaturaContrato(e.target.value)}
            style={{ border: inputPreenchido(emailAssinaturaContrato) }}
          />

          {errors.emailAssinaturaContrato && <span className="error">{errors.emailAssinaturaContrato}</span>}


          <h3>Valores dos Produtos Bazze</h3>
          <table className="styled-table">
            <thead>
              <tr>
                <th></th>
                <th>Valor Esquadrias (R$)</th>
                <th>Desconto (%)</th>
                <th>Valor Final (R$)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><label>Externas</label></td>

                <td><input type="text" name="esquadrias" value={valorExternasEsquadrias} onChange={(e) => setValorExternasEsquadrias(e.target.value)} style={{ border: inputPreenchido(valorExternasEsquadrias) }} /></td>

                <td><input type="text" name="desconto" value={descontoExternas} onChange={(e) => setDescontoExternas(e.target.value)} /></td>

                <td><input type="text" name="valor_final" value={formatCurrency(valorFinalExternas)} readOnly /></td>


              </tr>


              {errors.valorExternasEsquadrias && <span className="error">{errors.valorExternasEsquadrias}</span>}

              {errors.descontoExternas && <span className="error">{errors.descontoExternas}</span>}

            </tbody>

          </table>












          <div className='outros-container-pedidos'>

            <label>Outros</label>
            <input style={{ width: '120px', height: '40px', marginLeft: '10px' }} type="text" name="esquadrias" value={valorOutrosEsquadrias} onChange={(e) => setValorOutrosEsquadrias(e.target.value)} />
            {/* <td><input type="text" name="desconto" value={descontoOutros} onChange={(e) => setDescontoOutros(e.target.value)} /></td>
<td><input type="text" name="valor_final" value={formatCurrency(valorFinalOutros)} readOnly /></td> */}


            <label style={{ marginLeft: '10px' }}
            >Obs.:</label>
            <textarea
              name="observacoes_outros"
              value={observacoesOutros}
              onChange={(e) => setObservacoesOutros(e.target.value)}
              style={{ height: '40px', marginLeft: '10px' }}
            />

          </div>
          {errors.valorOutrosEsquadrias && <span className="error">{errors.valorOutrosEsquadrias}</span>}

          {errors.observacoesOutros && <span className="error">{errors.observacoesOutros}</span>}














          <h3>Valores dos Serviços Cobrados pela Bazze</h3>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Serviço</th>
                <th>Valor(R$)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><label>Instalação</label></td>
                <td><input type="text" name="valor_instalacao" value={valorInstalacao} onChange={(e) => setValorInstalacao(e.target.value)} /></td>
              </tr>
              <tr>
                <td><label>Frete</label></td>
                <td><input type="text" name="valor_frete" value={valorFrete} onChange={(e) => setValorFrete(e.target.value)} /></td>
              </tr>
              <tr>
                <td><label>Arredondamento</label></td>
                <td><input type="text" name="valor_abatimento_showroom" value={valorAbatimentoShowroom} onChange={(e) => setValorAbatimentoShowroom(e.target.value)} /></td>
              </tr>
            </tbody>
          </table>

          {errors.valorInstalacao && <span className="error">{errors.valorInstalacao}</span>}
          {errors.valorFrete && <span className="error">{errors.valorFrete}</span>}
          {errors.valorAbatimentoShowroom && <span className="error">{errors.valorAbatimentoShowroom}</span>}




          <h3>Valores Pagos pelo Cliente Diretamente ao Terceiro</h3>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Serviço / Produto</th>
                <th>Valor (R$)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><label>Instalação esquadrias pvc</label></td>
                <td><input type="text" name="valor_instalacao_pvc" value={valorInstalacaoPVC} onChange={(e) => setValorInstalacaoPVC(e.target.value)} /></td>
              </tr>
              <tr>
                <td><label>Frete das esquadrias</label></td>
                <td><input type="text" name="valor_frete_esquadrias" value={valorFreteEsquadrias} onChange={(e) => setValorFreteEsquadrias(e.target.value)} /></td>
              </tr>
              <tr>
                <td><label>Projeto, Gerenciamento e acompanhamento técnico</label></td>
                <td><input type="text" name="valor_projeto" value={valorProjeto} onChange={(e) => setValorProjeto(e.target.value)} /></td>
              </tr>
              <tr>
                <td><label>Vidros separados</label></td>
                <td><input type="text" name="valor_vidros_separados" value={valorVidrosSeparados} onChange={(e) => setValorVidrosSeparados(e.target.value)} /></td>
              </tr>
            </tbody>
          </table>

          {errors.valorInstalacaoPVC && <span className="error">{errors.valorInstalacaoPVC}</span>}

          {errors.valorFreteEsquadrias && <span className="error">{errors.valorFreteEsquadrias}</span>}

          {errors.valorProjeto && <span className="error">{errors.valorProjeto}</span>}

          {errors.valorVidrosSeparados && <span className="error">{errors.valorVidrosSeparados}</span>}


          <div className="valor-total-container">
            <h3 style={{ width: '240px' }}>Valor Total Bazze</h3>
            <input type="text" name="valor_total_bazze" value={formatCurrency(calculateTotalProdutos() + calculateTotalServicos())} readOnly className="valor-total-input" />
          </div>

          <div className="valor-total-container">
            <h3 style={{ width: '300px' }}>Valor Total de Terceiros</h3>
            <input type="text" name="valor_total_terceiros" value={formatCurrency(calculateTotalTerceiros())} readOnly className="valor-total-input" />
          </div>

          <div className="valor-total-container">
            <h3 style={{ width: '300px' }}>Valor Total do Contrato</h3>
            <input type="text" name="valor_total_contrato" value={formatCurrency(calculateTotalProdutos() + calculateTotalServicos() + calculateTotalTerceiros())} readOnly className="valor-total-input" />
          </div>


          <h3>Condições de Pagamento</h3>
          <textarea name="condicoes_pagamento" value={condicoesPagamento} onChange={(e) => setCondicoesPagamento(e.target.value)} />
          {errors.condicoesPagamento && <span className="error">{errors.condicoesPagamento}</span>}

          <h3>Condições de Pagamento Terceiro</h3>
          <textarea name="condicoes_pagamento_terceiro" value={condicoesPagamentoTerceiro} onChange={(e) => setCondicoesPagamentoTerceiro(e.target.value)} />

          <h3>Previsão de Medição</h3>
          <input type="date" name="previsao_medicao" value={previsaoMedicao} onChange={(e) => setPrevisaoMedicao(e.target.value)} style={{ border: inputPreenchido(previsaoMedicao) }} />

          {errors.previsaoMedicao && <span className="error">{errors.previsaoMedicao}</span>}


          <h3>Previsão de Entrega</h3>
          <input type="date" name="previsao_entrega" value={previsaoEntrega} onChange={(e) => setPrevisaoEntrega(e.target.value)} style={{ border: inputPreenchido(previsaoEntrega) }} />

          {errors.previsaoEntrega && <span className="error">{errors.previsaoEntrega}</span>}


          <h3>Observações</h3>
          <textarea name="observacoes" value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />

          <input
            type="number"
            name="comissao"
            value={comissao}
            onChange={(e) => setComissao(e.target.value)}
            style={{ width: '80px', marginLeft: '500px' }}
            placeholder='%'
          />

          {errors.comissao && <span className="error">{errors.comissao}</span>}



        </div>

        <div className='footer-form-pedido-container'>


           {/* Botões para salvar e carregar */}

           <div className='temporary-form-container'>
            <label>Temporário</label>
           <button className="btn-temporary-save" onClick={saveFormToLocalStorage}>Salvar</button>
          <button className="btn-temporary-load" onClick={loadFormFromLocalStorage}>Carregar</button>
          <button className="btn-temporary-delete" onClick={deleteFormFromLocalStorage}>Excluir</button>
           </div>


          {/* SEUS OUTROS CAMPOS DO FORMULÁRIO AQUI... */}



          <button className='btn-atualizar-pedido-form' onClick={handleSavePedido}>Solicitar Pedido</button>

          {user.access_level === 5 && (
            <button className="btn-save-only" onClick={updatePedido}>
              Atualizar
            </button>
          )}

        </div>


      </div>

    </div>
  );
};

export default PedidoPedido;
