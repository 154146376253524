import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalContext'; // 🔹 Corrigindo importação

const PrivateRoute = () => {
  const { token } = useContext(GlobalContext);

  return token ? <Outlet /> : <Navigate to="/login" />; // 🔹 Melhor para UX
};

export default PrivateRoute;
