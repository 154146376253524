import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiUrl } from '../../../config/apiConfig';
import api from '../../../services/api';

// STYLE
import './style.css';

// CONTEXT API
import { useColumns } from '../../../contexts/columnsContext';

function ClientePage() {
  const { columns } = useColumns();
  const { cardId } = useParams();
  const [cardData, setCardData] = useState(null);
  const [timeline, setTimeline] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (cardId) {
      fetchCardData(cardId);
    }
  }, [cardId]);

  // const fetchCardData = async (cardId) => {
  //   setError(null);

  //   try {
  //     const response = await axios.get(`${apiUrl}/users/pedido-status`, {
  //       params: { cardId },
  //     });

  //     if (!response.data) {
  //       setError('Nenhum pedido encontrado com esse código.');
  //       return;
  //     }

  //     const { card, columns } = response.data;
  //     setCardData(card);

  //     // const currentIndex = columns.findIndex(column => column.id === card.column_id);

  //     const currentIndex = columns.findIndex(column => column.id === card.column_id);

  //     if (currentIndex === -1) {
  //       setError('Erro: A coluna atual do card não existe na lista de colunas visíveis.');
  //       return;
  //     }


  //     const startIndex = Math.max(0, currentIndex - 3);
  //     const endIndex = Math.min(columns.length, currentIndex + 4);

  //     const timelineData = columns.slice(startIndex, endIndex).map((column, index) => ({
  //       name: column.name,
  //       isCurrent: column.id === card.column_id,
  //       color: column.id === card.column_id ? '#66cc66' : getColor(index),
  //     }));

  //     setTimeline(timelineData);

  //   } catch (err) {
  //     console.error('Erro ao buscar informações do pedido:', err);
  //     setError('Ocorreu um erro ao buscar as informações do pedido.');
  //   }
  // };

  const fetchCardData = async (cardId) => {
    setError(null);
  
    try {
      const response = await api.get("/users/pedido-status", {
        params: { cardId },
      });
  
      if (!response.data) {
        setError("Nenhum pedido encontrado com esse código.");
        return;
      }
  
      const { card, columns } = response.data;
      setCardData(card);
  
      const currentIndex = columns.findIndex((column) => column.id === card.column_id);
  
      if (currentIndex === -1) {
        setError("Erro: A coluna atual do card não existe na lista de colunas visíveis.");
        return;
      }
  
      const startIndex = Math.max(0, currentIndex - 3);
      const endIndex = Math.min(columns.length, currentIndex + 4);
  
      const timelineData = columns.slice(startIndex, endIndex).map((column, index) => ({
        name: column.name,
        isCurrent: column.id === card.column_id,
        color: column.id === card.column_id ? "#66cc66" : getColor(index),
      }));
  
      setTimeline(timelineData);
    } catch (err) {
      console.error("Erro ao buscar informações do pedido:", err);
      setError("Ocorreu um erro ao buscar as informações do pedido.");
    }
  };
  

  const getColor = (index) => {
    const colors = ['#66c2ff', '#99e6e6', '#ffcc66', '#ff6666', '#ff9966', '#ff6666'];
    return colors[index % colors.length];
  };

  return (
    <div className='cliente-page'>
      <header className='cliente-header'>
        <h1>Bem-vindo</h1>
        <p>Acompanhe aqui o seu pedido.</p>
      </header>

      {error && <p className='error-message'>{error}</p>}

      {cardData && (
        <div className='card-info'>
          <h2>Pedido {cardData.card_id}</h2>
          <p><strong>Empresa:</strong> {cardData.empresa_nome}</p>
          <p><strong>Cliente:</strong> {cardData.name}</p>
          <p><strong>Status:</strong> {cardData.status}</p>
          <p><strong>Produto:</strong> {cardData.produto || 'Não informado'}</p>
          <p><strong>Criado em:</strong> {new Date(cardData.created_at).toLocaleDateString()}</p>
          <p><strong>Última Atualização:</strong> {new Date(cardData.updated_at).toLocaleDateString()}</p>
          <p><strong>Parado há:</strong> {getDaysSinceUpdate(cardData.updated_at)} dias</p>
        </div>
      )}

      <h2 className='title-timeline'>Etapa atual:</h2>
      {timeline.length > 0 && (
        <div className='timeline-container'>
          <div className='timeline'>
            {timeline.map((step, index) => (
              <div
                key={index}
                className={`timeline-step ${step.isCurrent ? 'current-step' : ''}`}
                style={{ backgroundColor: step.color }}
              >
                <span className="timeline-text">{step.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function getDaysSinceUpdate(updatedAt) {
  const today = new Date();
  const lastUpdateDate = new Date(updatedAt);
  const differenceInTime = today - lastUpdateDate;
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  return differenceInDays;
}

export default ClientePage;
