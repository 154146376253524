import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api, { setupInterceptors } from "../services/api";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);


  useEffect(() => {
    console.log(token)
  }, [token]);



  // ✅ Carrega o token do localStorage ao iniciar
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedExpiration = localStorage.getItem("tokenExpiration");

    if (!storedToken || !storedExpiration) {
      console.warn("❌ Nenhum token encontrado. Usuário precisa logar.");
      return;
    }

    const expirationTime = parseInt(storedExpiration, 10);
    const currentTime = new Date().getTime();

    if (currentTime >= expirationTime) {
      console.warn("❌ Token expirado ao carregar a aplicação. Deslogando...");
      logout();
      return;
    }

    console.log("✅ Token carregado do localStorage:", storedToken);
    setToken(storedToken);
  }, []);

  // ✅ Verifica o tempo de expiração continuamente
  useEffect(() => {
    if (!token) return;

    const checkExpiration = () => {
      const storedExpiration = localStorage.getItem("tokenExpiration");
      if (!storedExpiration) return;

      const expirationTime = parseInt(storedExpiration, 10);
      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime) {
        console.warn("⏳ Token expirado! Deslogando usuário automaticamente...");
        //logout();
      }
    };

    // ✅ Checa a expiração a cada 5 segundos
    const interval = setInterval(checkExpiration, 500);

    return () => clearInterval(interval); // ✅ Limpa o intervalo ao desmontar
  }, [token]);

  // ✅ Função de logout automático
  // const logout = () => {
  //   console.warn("🔴 Fazendo logout automático...");
  //   setToken(null);
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("tokenExpiration");
  //   navigate("/");
  // };

  const logout = () => {
    console.warn("🔴 Fazendo logout automático...");
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiration");
  
    if (window.location.pathname !== "/login" && window.location.pathname !== "/") {
      navigate("/");
    }
  };
  
  

  // ✅ Configura os interceptores para capturar erro 401
  useEffect(() => {
    setupInterceptors(logout);
  }, []);

  const [currentId, setCurrentId] = useState(null);
  const [isEditOpenParticipant, setIsEditOpenParticipant] = useState(false);


  return (
    <GlobalContext.Provider value={{ token, setToken, logout, currentId, setCurrentId, isEditOpenParticipant, setIsEditOpenParticipant }}>
      {children}
    </GlobalContext.Provider>
  );
};
