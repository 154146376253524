// Definição do endpoint da API

//export const apiUrl = `http://localhost:3001`;
// export const fileApiUrl = 'http://localhost:3002';

export const fileApiUrl = 'https://bazzepvc.syncscrm.com.br:3000';
//export const apiUrl = `https://bbllrr.hospedagemelastica.com.br`;
export const apiUrl = ``;



